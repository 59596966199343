import { HttpService } from "services";
import { BulkUploadGroup } from "types/entities";
import {
  GetBulkGroupsFunction,
} from "types/functions";
import { AuthorizationLevel } from "types/http";

/**
 * ...
 *
 * @throws {HttpServiceException}
 */
export const getBulkGroups: GetBulkGroupsFunction = async () => {
  try {
    return await HttpService.fetch<BulkUploadGroup[]>(
      "/listing/bulk",
      {
        requestConfig: {
          method: "GET",
        },
      },
      AuthorizationLevel.AUTHORIZED
    );
  } catch (error) {
    throw error;
  }
};
