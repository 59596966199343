import { Box, Modal } from "@mui/material";
import React from "react";

interface OwnProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

type TCardModal = OwnProps;

export const CardModal: React.FC<TCardModal> = ({
  children,
  isOpen,
  onClose,
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={() => onClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>{children}</Box>
    </Modal>
  );
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 400,
  width: "100%",
  bgcolor: "background.paper",
  borderRadius: "4px",
  boxShadow: 24,
  p: 4,
};
