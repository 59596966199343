import { Box, Stack } from "@mui/material";
import {
  Button,
  ErrorMessage,
  LoadingIndicator,
  Table,
  Text,
} from "components/common";
import { MAX_GRID_ITEM_HEIGHT } from "consts/dimensions";
import { useGenerateTableData, useGetTitleDocument, useGetTitleDocuments } from "hooks";
import { useEffect, useState } from "react";
import { useTitleStore } from "store";
import {
  DetailedListing,
  EndpointTitle,
  EndpointTitleDocument,
  Listing,
} from "types/entities";
import { nullableDateTimeToString } from "utils/DateFormatter";
import { TitleUploadDocumentModal } from "components/title/TitleUploadDocumentModal";

interface OwnProps {
  listing: Listing | DetailedListing;
  selectedTitle: EndpointTitle | null;
}

export const TitleDocumentsTable: React.FC<OwnProps> = ({
  listing,
  selectedTitle,
}) => {
  const { getTitleDocuments, loading: loadingDocuments, error: errorDocuments } =
    useGetTitleDocuments();
  const { getTitleDocument, loading: loadingDocument, error: errorDocument } =
    useGetTitleDocument();
  const { titleDocuments } = useTitleStore();
  const [selectedTitleDocument, setSelectedTitleDocument] =
    useState<EndpointTitleDocument | null>(null);
  const [isUploadDocumentModalOpen, setIsUploadDocumentModalOpen] = useState(false);

  const titleDocumentsTableData = useGenerateTableData({
    columnNames: [
      "Id",
      "Name",
      "Type",
      "Escrow Note",
      "Customer Note",
      "Uploaded By",
      "Created At",
      "Size",
      "MIME Type",
    ],
    data: titleDocuments
      ? titleDocuments.map((doc) => ({
          hidden: [
            {
              name: "_id",
              value: doc.id,
            },
          ],
          visible: [
            doc.id,
            doc.name,
            doc.type,
            doc.escrowNote,
            doc.customerNote,
            doc.uploadedBy,
            nullableDateTimeToString(doc.createdAt),
            doc.size,
            doc.mimeType,
          ],
        }))
      : [],
    onRowClick: ({ _id }) => {
      const newSelectedTitleDocument = titleDocuments.find(
        (doc) => doc.id === _id
      );
      setSelectedTitleDocument(
        newSelectedTitleDocument ? { ...newSelectedTitleDocument } : null
      );
    },
  });

  const fetchTitleDocuments = () => {
    const transactionId = selectedTitle?.transactionId;
    if (transactionId)
      getTitleDocuments({
        listingId: listing.id,
        transactionId: selectedTitle?.transactionId,
      });
  }

  const handleViewTitleDocument = () => {
    if (listing?.id && selectedTitle?.transactionId && selectedTitleDocument?.id) {
      getTitleDocument({
        listingId: listing.id,
        transactionId: selectedTitle?.transactionId,
        documentId: selectedTitleDocument.id,
      }).then((base64) => {
        let pdfWindow = window.open("")
        pdfWindow?.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64," + encodeURI(base64) + "'></iframe>")
      })
    }
    
  }

  const handleCloseUploadDocumentModal = (success: boolean) => {
    setIsUploadDocumentModalOpen(false);
    if (success) {
      fetchTitleDocuments();
    }
  }

  useEffect(() => {
    fetchTitleDocuments();
  }, [listing]);

  return (
    <Box>
      <Text variant="h5" fontWeight={600}>
        Select a document
      </Text>
      {loadingDocuments ? (
        <LoadingIndicator />
      ) : (
        <Stack spacing={2}>
          <div style={{ maxHeight: MAX_GRID_ITEM_HEIGHT, overflow: "auto" }}>
            <Table
              tableData={titleDocumentsTableData}
              dataIdKey="_id"
              onRowClick={titleDocumentsTableData.onRowClick}
              selectedRowId={selectedTitleDocument?.id}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div style={{ flex: 1, marginRight: 16, overflow: "auto" }}>
              <Button
                variant="contained"
                fullWidth
                onClick={handleViewTitleDocument}
                disabled={!selectedTitleDocument || loadingDocument}
              >
                {loadingDocument ? <LoadingIndicator /> : "View document"}
              </Button>
              {errorDocument && <ErrorMessage />}
            </div>
            <div style={{ flex: 1, overflow: "auto" }}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => setIsUploadDocumentModalOpen(true)}
              >
                Upload document
              </Button>
              {errorDocument && <ErrorMessage />}
            </div>
          </div>
        </Stack>
      )}
      {errorDocuments && <ErrorMessage />}
      {listing.id && selectedTitle?.transactionId && (
        <TitleUploadDocumentModal
          isOpen={isUploadDocumentModalOpen}
          onClose={handleCloseUploadDocumentModal}
          listingId={listing.id}
          transactionId={selectedTitle?.transactionId}
        />
      )}
    </Box>
  );
};
