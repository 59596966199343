import React, { FormEvent, useState, useEffect } from "react";

import { useCreateUser } from "hooks";
import { UserRole, VendorRole } from "types/entities";
import { Grid, Stack } from "@mui/material";
import {
  Button,
  CheckboxGroup,
  InputField,
  RadioGroup,
  Text,
} from "components/common";
import { ManageUsersTable } from "components/user";
import { MAX_GRID_ITEM_HEIGHT } from "consts/dimensions";
import { ViewWrapper } from "components/wrappers";
import { message, notification } from "antd";

export const ManageUsers: React.FC = () => {
  const {
    createUser,
    loading: createUserLoading,
    error: createUserError,
  } = useCreateUser();

  const [givenName, setGivenName] = useState<string>("");
  const [familyName, setFamilyName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [userRoles, setUserRoles] = useState<Set<UserRole>>(
    new Set([UserRole.CUSTOMER])
  );
  const [vendorRole, setVendorRole] = useState<VendorRole>(
    VendorRole.INSPECTOR
  );
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    if (createUserError) {
      api.error({
        message: `Error while adding user`,
        placement: "bottomRight",
      });
    }
  }, [createUserError]);

  const handleAddUser = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (email.length > 0 && givenName.length > 0 && familyName.length > 0) {
      const res = await createUser({
        email,
        familyName,
        givenName,
        roles: userRoles,
        vendorRole,
      });
      if (!res) return;
      api.success({
        message: `Successfully added user`,
        placement: "bottomRight",
      });
    }
  };

  return (
    <ViewWrapper title="Manage users">
      {contextHolder}
      <Stack>
        <Grid container style={{ marginBottom: 20 }}>
          <Grid
            xs={12}
            item
            maxHeight={MAX_GRID_ITEM_HEIGHT}
            style={{ overflow: "auto" }}
          >
            <ManageUsersTable />
          </Grid>
        </Grid>
        <Text variant="h4" fontWeight={600} marginBottom="20px">
          Create user
        </Text>
        <form onSubmit={handleAddUser}>
          <Stack spacing={2}>
            <InputField
              label="Given name"
              type="text"
              variant="outlined"
              required
              value={givenName}
              onChange={setGivenName}
            />
            <InputField
              label="Family name"
              type="text"
              variant="outlined"
              required
              value={familyName}
              onChange={setFamilyName}
            />
            <InputField
              label="Email"
              type="email"
              variant="outlined"
              required
              value={email}
              onChange={setEmail}
            />
            <CheckboxGroup<UserRole>
              label="Choose user roles"
              onChange={setUserRoles}
              options={[
                {
                  label: "Listing specialist",
                  value: UserRole.LISTING_SPECIALIST,
                },
                {
                  label: "Administrator",
                  value: UserRole.ADMINISTRATOR,
                },
                {
                  label: "Vendor",
                  value: UserRole.VENDOR,
                },
              ]}
              value={userRoles}
            />
            {userRoles.has(UserRole.VENDOR) ? (
              <RadioGroup<VendorRole>
                onChange={setVendorRole}
                value={vendorRole}
                label="Vendor role"
                name="vendor-role"
                options={[
                  {
                    label: "Inspector",
                    value: VendorRole.INSPECTOR,
                  },
                  {
                    label: "Photographer",
                    value: VendorRole.PHOTOGRAPHER,
                  },
                  {
                    label: "Title company",
                    value: VendorRole.TITLE_COMPANY,
                  },
                ]}
              />
            ) : null}
            <Button
              variant="contained"
              type="submit"
              disabled={createUserLoading}
            >
              Create
            </Button>
          </Stack>
        </form>
      </Stack>
    </ViewWrapper>
  );
};
