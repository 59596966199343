import { HttpService } from "services";
import { Listing } from "types/entities";

import {
  GetListingsFunction,
  GetListingsFunctionParams,
} from "types/functions";
import { AuthorizationLevel } from "types/http";
import { generateQueryString } from "utils";

/**
 * Retrieve listings
 *
 * @throws {HttpServiceException}
 */
export const getListings: GetListingsFunction = async (
  params?: GetListingsFunctionParams
) => {
  try {
    let url = "/listing";
    if (params) {
      const { awaitingApproval } = params;
      url += generateQueryString({ awaitingApproval: awaitingApproval?.toString() })
    }

    const res: Listing[] = await HttpService.fetch(
      url,
      {
        requestConfig: {
          method: "GET",
        },
      },
      AuthorizationLevel.AUTHORIZED
    );

    return res;
  } catch (error) {
    throw error;
  }
};
