import { HttpService } from "services";
import { EndpointTitle } from "types/entities";

import { GetTitlesFunction, GetTitlesFunctionParams } from "types/functions";
import { AuthorizationLevel } from "types/http";
import { generateQueryString } from "utils";

/**
 * Retrieve titles
 * 
 * @throws {HttpServiceException}
 */
export const getTitles: GetTitlesFunction = async (
  params: GetTitlesFunctionParams
) => {
  try {
    const { listingId } = params;
    let url = `/title${generateQueryString({ listingId })}`;
    
    const res: EndpointTitle[] = await HttpService.fetch(
      url,
      {
        requestConfig: {
          method: "GET",
        },
      },
      AuthorizationLevel.AUTHORIZED
    );

    return res;
  } catch (error) {
    throw error;
  }
};
