import {
  TableDataItem,
  TableDataItemValue,
  TableHeaderItem,
} from "types/components";

type UseGenerateTableDataClick = (
  item: Record<string, TableDataItemValue>
) => void;

interface UseGenerateTableDataInput {
  columnNames: string[];
  data: {
    visible: TableDataItemValue[];
    hidden?: {
      name: `_${string}`;
      value: TableDataItemValue;
    }[];
  }[];
  onRowClick?: UseGenerateTableDataClick;
}

interface UseGenerateTableDataReturnType {
  onRowClick: UseGenerateTableDataClick;
  headers: TableHeaderItem[];
  data: TableDataItem[];
}

/**
 * Hook that serves as a simpler interface for creating Table components props.
 *
 * Remember to pass columnNames in the same order as data.visibile
 *
 * data.hidden.name must begin with an underscore (beacuse Table component ignores
 * columns that begin with underscore)
 *
 * @see{components/common/Table}
 *
 */
export const useGenerateTableData = (
  props: UseGenerateTableDataInput
): UseGenerateTableDataReturnType => {
  const { columnNames, data, onRowClick } = props;

  const keys = columnNames.map((n) => n.replaceAll(" ", "_").toLowerCase());
  const headers: TableHeaderItem[] = keys.map((k, i) => ({
    key: k,
    title: columnNames[i],
  }));

  const concatenatedData: TableDataItem[] = data.map(({ visible, hidden }) => {
    const data: TableDataItem = {};
    visible.forEach((value, i) => {
      data[keys[i]] = value;
    });
    if (hidden) {
      hidden.forEach(({ name, value }) => {
        data[name] = value;
      });
    }
    return data;
  });

  return {
    data: concatenatedData,
    headers,
    onRowClick: onRowClick ?? (() => {}),
  };
};
