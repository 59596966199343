import { Box, Container, Stack } from "@mui/material";
import React, { FormEvent, useEffect, useState } from "react";

import {
  Button,
  CardModal,
  InputField,
  Link,
  PaperSurface,
  Text,
  ErrorMessage,
} from "components/common";
import { UNATH_ROUTER_PATHS } from "types/navigation";
import { useResetPassword } from "hooks";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";

interface OwnProps {}

type TResetPassword = OwnProps;

export const ResetPassword: React.FC<TResetPassword> = () => {
  const {
    generateResetCode,
    isGenerateResetCodeLoading,
    isResetPasswordLoading,
    isVerifyResetCodeLoading,
    resetPassword,
    verifyResetCode,
    resetPasswordError,
  } = useResetPassword();
  const navigate = useNavigate();
  const { didHappen: isResetPasswordError } = resetPasswordError;
  const [email, setEmail] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmedPassword, setConfirmedPassword] = useState<string>("");
  const [passwordsMatch, setPasswordsMatch] = useState<boolean>(false);
  const [code, setCode] = useState<string>("");
  const [codeGenerated, setCodeGenerated] = useState<boolean>(false);
  const [isCodeVerified, setIsCodeVerified] = useState<boolean>(false);
  const [isPasswordReseted, setIsPasswordReseted] = useState<boolean>(false);
  const [isOpenVerifyCodeModal, setIsOpenVerifyCodeModal] =
    useState<boolean>(false);
  const [isOpenResetPassModal, setIsOpenResetPassModal] =
    useState<boolean>(false);
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    if (codeGenerated === true) {
      setIsOpenVerifyCodeModal(true);
    }
  }, [codeGenerated]);

  useEffect(() => {
    if (isCodeVerified === true) {
      setIsOpenVerifyCodeModal(false);
      setIsOpenResetPassModal(true);
    }
  }, [isCodeVerified]);

  useEffect(() => {
    if (isResetPasswordError === false && isPasswordReseted) {
      navigate(UNATH_ROUTER_PATHS.SIGN_IN);
    }
  }, [isPasswordReseted, isResetPasswordError, navigate]);

  useEffect(() => {
    const { didHappen } = resetPasswordError;
    if (didHappen === true) {
      window.alert(resetPasswordError.message);
    }
  }, [resetPasswordError]);

  // Procedure that should be called whenever someone decides to resign from resetting password flow. Closing a modal for example.
  const quitResetPasswordFlow = () => {
    setNewPassword("");
    setCode("");
    setCodeGenerated(false);
    setIsCodeVerified(false);
    setIsOpenVerifyCodeModal(false);
    setIsOpenResetPassModal(false);
  };

  const handleGenerateCodeFormSubmit = async (
    e: FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();

    if (email.length > 0) {
      await generateResetCode(email);
      api.success({
        message: `Successfully generated reset code`,
        placement: "bottomRight",
      });
      setCodeGenerated(true);
    }
  };

  const handleVerifyCodeFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (email.length > 0) {
      await verifyResetCode({ code, email });
      api.success({
        message: `Successfully verified code`,
        placement: "bottomRight",
      });
      setIsCodeVerified(true);
    }
  };

  const handleResetPasswordFormSubmit = async (
    e: FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    setIsPasswordReseted(false);

    if (
      email.length > 0 &&
      newPassword.length > 0 &&
      confirmedPassword.length > 0
    ) {
      await resetPassword(email, newPassword, code);
      api.success({
        message: `Successfully reset password`,
        placement: "bottomRight",
      });
      setIsPasswordReseted(true);
    }
  };

  useEffect(
    () => setPasswordsMatch(newPassword === confirmedPassword),
    [newPassword, confirmedPassword]
  );

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {contextHolder}
      <Container maxWidth="sm">
        <Stack alignItems="center">
          <Text variant="h4" fontWeight={500} sx={{ marginBottom: "20px" }}>
            Reset password
          </Text>
          <PaperSurface
            elevation={3}
            sx={{
              padding: "45px 40px",
              width: "100%",
            }}
          >
            <form onSubmit={handleGenerateCodeFormSubmit}>
              <Stack spacing={2}>
                <InputField
                  label="Email"
                  type="email"
                  variant="outlined"
                  required
                  value={email}
                  onChange={setEmail}
                />
                <Button
                  variant="contained"
                  type="submit"
                  disabled={isGenerateResetCodeLoading}
                >
                  Reset
                </Button>
              </Stack>
            </form>
          </PaperSurface>
          <Box sx={{ marginTop: "15px" }}>
            <Link path={UNATH_ROUTER_PATHS.SIGN_IN}>
              <Text>Sign in</Text>
            </Link>
          </Box>
        </Stack>
      </Container>
      <CardModal
        isOpen={isOpenVerifyCodeModal}
        onClose={() => quitResetPasswordFlow()}
      >
        <form onSubmit={handleVerifyCodeFormSubmit}>
          <Stack spacing={2}>
            <Text>
              Please check your email inbox for a message from us.{"\n"}Paste
              the 4-digit code we provided into the field below.
            </Text>
            <InputField
              label="Code"
              type="text"
              variant="outlined"
              required
              value={code}
              onChange={setCode}
            />
            <Button
              variant="contained"
              type="submit"
              disabled={isVerifyResetCodeLoading}
            >
              Verify code
            </Button>
          </Stack>
        </form>
      </CardModal>
      <CardModal
        isOpen={isOpenResetPassModal}
        onClose={() => quitResetPasswordFlow()}
      >
        <form onSubmit={handleResetPasswordFormSubmit}>
          <Stack spacing={2}>
            <InputField
              label="New password"
              type="password"
              variant="outlined"
              required
              value={newPassword}
              onChange={setNewPassword}
            />
            <InputField
              label="Old password"
              type="password"
              variant="outlined"
              required
              value={confirmedPassword}
              onChange={setConfirmedPassword}
            />
            {!passwordsMatch && (
              <ErrorMessage descriptionText="Passwords do not match" />
            )}
            <Button
              variant="contained"
              type="submit"
              disabled={isResetPasswordLoading || !passwordsMatch}
            >
              Change password
            </Button>
          </Stack>
        </form>
      </CardModal>
    </Box>
  );
};
