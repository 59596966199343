import { OfferService } from 'services';
import { Offer } from 'types/entities';
import { GetOffersFunction } from 'types/functions/getOffers';
import create from 'zustand';

interface OffersStore {
  offers: Offer[],
  getOffers: GetOffersFunction,
}

export const useOffersStore = create<OffersStore>((set) => ({
  offers: [],
  getOffers: async (...args: Parameters<GetOffersFunction>) => {
    try {
      const offers = await OfferService.getOffers(...args);
      set({ offers });
      
      return offers;
    } catch (error) {
      throw error;
    }
  },
}));