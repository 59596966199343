import { HttpService } from "services";

import { RemoveDocsFunction, RemoveDocsParams } from "types/functions";
import { AuthorizationLevel } from "types/http";

/**
 * Mark selected documents as inspection documents
 * 
 * @throws {HttpServiceException}
 */
export const removeDocs: RemoveDocsFunction = async (
  params: RemoveDocsParams,
) => {
  try {
    const { documentIds } = params;
    let url = `/document`;
    
    await HttpService.fetch(
      url,
      {
        requestConfig: {
          method: "DELETE",
          data: { documentIds }, 
        },
      },
      AuthorizationLevel.AUTHORIZED
    );

    return true;
  } catch (error) {
    throw error;
  }
};
