import { HttpService } from "services";
import { Event } from "types/entities";
import { GetEventsFunction } from "types/functions";
import { AuthorizationLevel } from "types/http";
import { generateQueryString } from "utils";

/**
 * Retrieve events as a vendor (all or per listing)
 *
 * @throws {EventServiceException}
 */
export const getEvents: GetEventsFunction = async (
  ...args: Parameters<GetEventsFunction>
) => {
  try {
    const [params] = args;

    let url = `/event`;
    let listingId;
    if (params) {
      listingId = params.listingId;
    }

    let events: Event[] = [];
    let response: Event[] = await HttpService.fetch(
      `${url}${generateQueryString({ listingId, type: "photo-shoot" })}`,
      {
        requestConfig: {
          method: "GET",
        },
      },
      AuthorizationLevel.AUTHORIZED
    );

    events = [...response];
    response = await HttpService.fetch(
      `${url}${generateQueryString({ listingId, type: "inspection" })}`,
      {
        requestConfig: {
          method: "GET",
        },
      },
      AuthorizationLevel.AUTHORIZED
    );
    events = [...events, ...response];

    return events;
  } catch (error) {
    throw error;
  }
};
