import create from "zustand";

import { MessagesService } from "services";
import { GetChannelsFunction } from "types/functions";
import { GroupChannel } from "sendbird";

interface MessagesStore {
  channels: GroupChannel[];
  getChannels: GetChannelsFunction;
}

export const useMessagesStore = create<MessagesStore>((set, get) => ({
  channels: [],
  getChannels: async (...args: Parameters<GetChannelsFunction>) => {
    try {
      const channels = await MessagesService.getChannels(...args);
      set({ channels });
      return channels;
    } catch (error) {
      throw error;
    }
  },
}));
