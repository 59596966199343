import {
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup as MuiRadioGroup,
} from "@mui/material";
import React from "react";

interface OwnProps<T> {
  options: {
    label: string;
    value: T;
  }[];
  value: T;
  label: string;
  name: string;
  onChange: (value: T) => void;
}

type TRadioGroup<T> = OwnProps<T>;

export const RadioGroup = <T,>({
  name,
  onChange,
  options,
  value,
  label,
}: TRadioGroup<T>) => {
  return (
    <FormControl>
      <FormLabel id={name}>{label}</FormLabel>
      <MuiRadioGroup
        aria-labelledby={name}
        value={value}
        name={name}
        onChange={(_, value: any) => onChange(value as T)}
        row
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.label}
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </MuiRadioGroup>
    </FormControl>
  );
};
