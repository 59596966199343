import { useState } from "react";
import { useAppSettingsStore } from "store";
import { AppPrices, AppPriceType } from "types/entities";

import { BaseAsyncHook } from "types/hooks";
import { AppPricesInStore } from "types/store";
import { formatAppPrices } from "utils";

type SetAppPricesHookFunction = (prices: AppPrices) => Promise<void>;

interface UseSetAppPrices extends BaseAsyncHook {
  loading: boolean;
  error: boolean;
  appPrices: AppPricesInStore;
  setAppPrices: SetAppPricesHookFunction;
}

export const useSetAppPrices = (): UseSetAppPrices => {
  const _appPrices = useAppSettingsStore((state) => state.appPrices);
  const _setAppPrices = useAppSettingsStore((state) => state.setAppPrices);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const setAppPrices = async (
    ...args: Parameters<SetAppPricesHookFunction>
  ) => {
    try {
      setIsLoading(true);
      setIsError(false);
      const [prices] = args;

      const serializedPrices: Record<string, number> = {};

      (Object.keys(prices) as AppPriceType[]).forEach((priceType) => {
        serializedPrices[priceType] = prices[priceType]!.price * 100;
      });

      // It will work because AppPriceType enum has string values anyways.
      await _setAppPrices(serializedPrices);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error: isError,
    loading: isLoading,
    appPrices: formatAppPrices(_appPrices),
    setAppPrices,
  };
};
