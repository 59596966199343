import { HttpService } from "services";

import {
  SetFinalDocumentHashFunction,
  SetFinalDocumentHashParams,
} from "types/functions";
import { AuthorizationLevel } from "types/http";
import { generateQueryString } from "utils";

/**
 * Set a production document hash on a given listing/offer document type
 *
 * @throws {HttpServiceException}
 */
export const setFinalDocumentHash: SetFinalDocumentHashFunction = async (
  params: SetFinalDocumentHashParams
) => {
  try {
    const { listingId, offerId, eversignDocumentType, eversignDocumentHash } = params;
    const url = `/document/set-final-document-hash${generateQueryString({ listingId, offerId, eversignDocumentHash, eversignDocumentType })}`;

    await HttpService.fetch(
      url,
      {
        requestConfig: {
          method: "POST",
        },
      },
      AuthorizationLevel.AUTHORIZED
    );

    return true;
  } catch (error) {
    throw error;
  }
};
