import { HttpService } from "services";
import { UserRole } from "types/entities";

import { CreateUserFunction } from "types/functions";
import { AuthorizationLevel } from "types/http";

/**
 * Create user as a administrator
 *
 * @throws {UserServiceException}
 */
export const createUser: CreateUserFunction = async (
  ...args: Parameters<CreateUserFunction>
) => {
  try {
    const { familyName, givenName, roles: role, vendorRole, email } = args[0];
    await HttpService.fetch(
      "/user/create-account",
      {
        requestConfig: {
          method: "POST",
          data: {
            email,
            given_name: givenName,
            family_name: familyName,
            is_admin: role ? role.has(UserRole.ADMINISTRATOR) : false,
            is_listing_specialist: role
              ? role.has(UserRole.LISTING_SPECIALIST)
              : false,
            is_vendor: role ? role.has(UserRole.VENDOR) : false,
            vendor_role: vendorRole,
          },
        },
      },
      AuthorizationLevel.AUTHORIZED
    );

    return true;
  } catch (error) {
    throw error;
  }
};
