import { EntityId } from "types/common";
import { Listing } from "./listing";

export enum EventStatus {
  PENDING = "pending",
  DENIED = "denied",
  ACCEPTED = "accepted",
  CANCELLED = "cancelled",
}

export enum EventType {
  SHOWING = "showing",
  PHOTOSHOOT = "photo-shoot",
  INSPECTION = "inspection",
  FINAL_WALKTHROUGH = "final-walkthrough",
}

// @TODO: Refactor, PICK these properties from User
// https://www.typescriptlang.org/docs/handbook/utility-types.html#picktype-keys
export interface EventParticipant {
  id: EntityId;
  given_name: string;
  family_name: string;
  profile_picture: string | null;
  telephone_number: string | null;
}

export interface Event {
  id: EntityId;
  status: EventStatus;
  date_specific: string;
  minutes_start: number;
  minutes_end: number;
  listing_id: string;
  feedback_created: boolean;
  lockbox_accessed_at: null | string;
  type: EventType;
  invitee: EventParticipant;
  inviter: EventParticipant;
  listing: Listing;
}
