import { HttpService } from "services";
import { DetailedListing, Event } from "types/entities";
import { UploadBulkListingFunction } from "types/functions";
import { AuthorizationLevel } from "types/http";

/**
 * Upload many listings in a bulk.
 *
 * @throws {HttpServiceException}
 */
export const uploadBulkListing: UploadBulkListingFunction = async (
  ...args: Parameters<UploadBulkListingFunction>
) => {
  try {
    const { data } = args[0];

    await HttpService.fetch<[DetailedListing, Event][]>(
      "/listing/bulk",
      {
        requestConfig: {
          method: "POST",
          data: {
            data,
          },
        },
      },
      AuthorizationLevel.AUTHORIZED
    );
  } catch (error) {
    throw error;
  }
};
