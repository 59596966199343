import { HttpService } from "services";

import {
  SetWalkthroughUrlFunction,
  SetWalkthroughUrlParams,
} from "types/functions";
import { AuthorizationLevel } from "types/http";

/**
 * Set virtual walkthrough url on a listing
 *
 * @throws {HttpServiceException}
 */
export const setWalkthroughUrl: SetWalkthroughUrlFunction = async (
  params: SetWalkthroughUrlParams
) => {
  try {
    const { listingId, url: virtualWalkthroughUrl } = params;
    let url = `/listing/${listingId}/virtual-walkthrough`;

    const res: boolean = await HttpService.fetch(
      url,
      {
        requestConfig: {
          method: "PATCH",
          data: {
            virtual_walkthrough_url: virtualWalkthroughUrl,
          },
        },
      },
      AuthorizationLevel.AUTHORIZED
    );

    return res;
  } catch (error) {
    throw error;
  }
};
