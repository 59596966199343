import { HttpService } from "services";
import { Listing } from "types/entities";

import { GetListingFunction, GetListingFunctionParams } from "types/functions";
import { AuthorizationLevel } from "types/http";

/**
 * Retrieve detailed information about listing
 * 
 * @throws {HttpServiceException}
 */
export const getListing: GetListingFunction = async (
  params: GetListingFunctionParams
) => {
  try {
    const { listingId } = params;
    let url = `/listing/${listingId}`;
    
    const res: Listing = await HttpService.fetch(
      url,
      {
        requestConfig: {
          method: "GET",
        },
      },
      AuthorizationLevel.AUTHORIZED
    );

    return res;
  } catch (error) {
    throw error;
  }
};
