import React from "react"
import { Photo } from "types/entities";

type OwnProps = {
  photos: Photo[],
};

const IMAGE_DIMENSIONS = {
  WIDTH: 300,
  HEIGHT: 300,
  MARGIN: 10,
};

export const HorizontalPhotoList: React.FC<OwnProps> = ({ photos }) => {

  if (!photos.length) return null;

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        margin: "auto",
      }}
    >
      {photos.map((photo) => (
        <img
          key={photo.file_name}
          src={photo.data}
          alt={photo.file_name}
          style={{
            width: IMAGE_DIMENSIONS.WIDTH,
            height: IMAGE_DIMENSIONS.HEIGHT,
            margin: IMAGE_DIMENSIONS.MARGIN,
            objectFit: "cover",
          }}
        />
      ))}
    </div>
  );
  
};