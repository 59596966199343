import { Box, Stack } from "@mui/material";
import {
  ErrorMessage,
  ListTable,
  LoadingIndicator,
  Table,
  Text,
} from "components/common";
import { useGenerateTableData } from "hooks";
import { useGetTitleStatus } from "hooks";
import { useEffect } from "react";
import { useTitleStore } from "store";
import {
  DetailedListing,
  EndpointTitle,
  Listing,
} from "types/entities";
import { nullableDateTimeToString } from "utils/DateFormatter";

interface OwnProps {
  listing: Listing | DetailedListing;
  selectedTitle: EndpointTitle | null;
}

export const TitleStatusTable: React.FC<OwnProps> = ({
  listing,
  selectedTitle,
}) => {
  const { getTitleStatus, loading, error } = useGetTitleStatus();
  const { titleStatus } = useTitleStore();

  const statusTableData = useGenerateTableData({
    columnNames: ["Prelim", "In Escrow", "Cancelled", "Closed"],
    data: [
      {
        hidden: [
          {
            name: "_id",
            value: titleStatus?.transactionId,
          },
        ],
        visible: [
          nullableDateTimeToString(titleStatus?.prelim),
          nullableDateTimeToString(titleStatus?.inEscrow),
          nullableDateTimeToString(titleStatus?.cancelled),
          nullableDateTimeToString(titleStatus?.closed),
        ],
      },
    ],
  });

  const sellerMilestonesTableData = useGenerateTableData({
    columnNames: ["Id", "Milestone Type", "Completed Date"],
    data: titleStatus?.milestones?.seller
      .sort(
        (milestone1, milestone2) => milestone1?.position - milestone2?.position
      )
      .map((milestone) => ({
        hidden: [
          {
            name: "_id",
            value: milestone?.id,
          },
        ],
        visible: [
          milestone?.id,
          milestone?.milestoneType,
          nullableDateTimeToString(milestone?.completedDate),
        ],
      })) ?? [{ hidden: [], visible: [] }],
  });

  const buyerMilestonesTableData = useGenerateTableData({
    columnNames: ["Id", "Milestone Type", "Completed Date"],
    data: titleStatus?.milestones?.buyer
      .sort(
        (milestone1, milestone2) => milestone1?.position - milestone2?.position
      )
      .map((milestone) => ({
        hidden: [
          {
            name: "_id",
            value: milestone?.id,
          },
        ],
        visible: [
          milestone?.id,
          milestone?.milestoneType,
          nullableDateTimeToString(milestone?.completedDate),
        ],
      })) ?? [{ hidden: [], visible: [] }],
  });

  useEffect(() => {
    const transactionId = selectedTitle?.transactionId;
    if (transactionId)
      getTitleStatus({
        listingId: listing.id,
        transactionId: transactionId,
      });
  }, [selectedTitle]);

  return (
    <Stack spacing={2}>
      <Box>
        <Text variant="h5" fontWeight={600}>
          Status
        </Text>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <ListTable tableData={statusTableData} />
        )}
        {error && <ErrorMessage />}
      </Box>
      {!loading && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <div style={{ flex: 1, marginRight: 16, overflow: 'auto' }}>
            <Text variant="h5" fontWeight={600}>
              Seller milestones
            </Text>
            <Table tableData={sellerMilestonesTableData} dataIdKey="_id" />
            {error && <ErrorMessage />}
          </div>

          <div style={{ flex: 1, overflow: 'auto' }}>
            <Text variant="h5" fontWeight={600}>
              Buyer milestones
            </Text>
            <Table tableData={buyerMilestonesTableData} dataIdKey="_id" />
            {error && <ErrorMessage />}
          </div>
        </div>
      )}
    </Stack>
  );
};
