import { useState } from "react";

import { usePhotoStore } from "store";
import { RemovePhotoFunction } from "types/functions/removePhoto";
import { BaseAsyncHook } from "types/hooks";

interface UseRemovePhoto extends BaseAsyncHook {
  removePhoto: RemovePhotoFunction;
}

export const useRemovePhoto = (): UseRemovePhoto => {
  const _storeFunc = usePhotoStore((state) => state.removePhoto);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const removePhoto = async (...args: Parameters<RemovePhotoFunction>) => {
    try {
      setIsLoading(true);
      setIsError(false);
      const isSuccess = await _storeFunc(args[0]);
      return isSuccess;
    } catch (error) {
      setIsError(true);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error: isError,
    loading: isLoading,
    removePhoto,
  };
};
