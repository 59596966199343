import { HttpService } from "services";
import { EndpointDownloadedTitleDocument } from "types/entities";
import {
  GetTitleDocumentFunction,
  GetTitleDocumentFunctionParams,
} from "types/functions";
import { AuthorizationLevel } from "types/http";
import { generateQueryString } from "utils";

/**
 * Retrieve title documents
 *
 * @throws {HttpServiceException}
 */
export const getTitleDocument: GetTitleDocumentFunction = async (
  params: GetTitleDocumentFunctionParams
) => {
  try {
    const { listingId, transactionId, documentId } = params;
    let url = `/title/${transactionId}/documents/${documentId}${generateQueryString({ listingId, convertToBase64: 'true' })}`;

    const res: EndpointDownloadedTitleDocument = await HttpService.fetch(
      url,
      {
        requestConfig: {
          method: "GET",
        },
      },
      AuthorizationLevel.AUTHORIZED
    );

    return res;
  } catch (error) {
    throw error;
  }
};
