import dayjs from "dayjs";
import { useGetVendors, useGetVendorsWithTimeslots } from "hooks";
import { useEffect, useMemo, useState } from "react";
import { Event, VendorRole } from "types/entities";

import { BaseAsyncHook } from "types/hooks";
import { UsersInStore } from "types/store";
import { DateFormatter, matchEventTypeWithVendorRole } from "utils";

type EventInUseGetAvailableVendorsForEvent = Event | undefined;

interface UseGetAvailableVendorsForEvent extends BaseAsyncHook {
  /**
   * Whether it is safe to consider vendors as ready to consume. This is a
   * informational flag for clients.
   */
  areVendorsReady: boolean;
  vendors: UsersInStore;
  selectedEvent: EventInUseGetAvailableVendorsForEvent;
  selectEvent: (e: EventInUseGetAvailableVendorsForEvent) => void;
}

/**
 * Hook that serves as a helper layer for fetching vendors for a given event.
 *
 * First it fetches Vendors with certain role and available at given date
 * (it knows the role and date from selected event) and then it filters Vendors
 * that fits for selected event date time.
 */
export const useGetAvailableVendorsForEvent =
  (): UseGetAvailableVendorsForEvent => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [selectedEvent, setSelectedEvent] =
      useState<EventInUseGetAvailableVendorsForEvent>();
    const [isSelectedEventFulfilled, setIsSelectedEventFulfilled] =
      useState<boolean>(false);

    // Get vendors only from local global store.
    const { vendors: _vendors } = useGetVendors({
      configuration: {
        preventNetworkRequest: true,
      },
    });

    // Memoize date (`new Date()` will create new instance on every render causing infinite rerenders)
    const memoizedDateForTimeslot = useMemo(
      () =>
        selectedEvent
          ? dayjs(selectedEvent.date_specific).toDate()
          : new Date(),
      [selectedEvent]
    );

    /**
     * Getting vendors and their timeslots basing on event's properties.
     *
     * When event is not yet passed to the hook, arbitrary fallback values will be
     * passed to the `useGetVendorsWithTimeslots`. Notice that this won't have any
     * effect anyway because HTTP request is protected with `preventNetworkRequest`
     * when there is no event yet.
     *
     */
    const {
      vendorIdDateTimeslotsHashMap,
      loading: vendorsWithTimeslotsLoading,
      error: vendorsWithTimeslotsError,
    } = useGetVendorsWithTimeslots({
      date: memoizedDateForTimeslot,
      role: selectedEvent
        ? matchEventTypeWithVendorRole(selectedEvent.type)
        : VendorRole.INSPECTOR,
      configuration: {
        preventNetworkRequest: selectedEvent === undefined ? true : false,
      },
    });

    // On every new `selectedEvent` reset fulfillment flag.
    useEffect(() => {
      setIsSelectedEventFulfilled(false);
    }, [selectedEvent]);

    // When loading is finished and vendors and not undefined, mark `selectedEvent` as fulfilled.
    useEffect(() => {
      if (
        _vendors &&
        vendorIdDateTimeslotsHashMap &&
        vendorsWithTimeslotsLoading === false
      ) {
        setIsSelectedEventFulfilled(true);
      }
    }, [_vendors, vendorIdDateTimeslotsHashMap, vendorsWithTimeslotsLoading]);

    /**
     * Select Vendors whose time slots matches with selected event's date start
     * and date end.
     */
    const availableVendors = useMemo(() => {
      if (vendorsWithTimeslotsLoading || !_vendors || !selectedEvent) return;

      const matchedVendors: UsersInStore = [];
      try {
        setIsLoading(true);

        _vendors.forEach((v) => {
          const vendorAvailableDates = vendorIdDateTimeslotsHashMap[v.id];
          // If this Vendor has no available dates then omit this Vendor.
          if (!vendorAvailableDates) return;
          const timeslots =
            vendorAvailableDates[
              DateFormatter.toDateString(selectedEvent.date_specific)
            ];
          // Omit Vendors with no timeslots.
          if (!timeslots || timeslots.length === 0) return;
          const hasAvailableTimeslotsForEvent =
            timeslots.find(
              (ts) =>
                ts.minutesStart === selectedEvent.minutes_start &&
                ts.minutesEnd === selectedEvent.minutes_end
            ) !== undefined;
          // No timeslots match selected event, omit.
          if (!hasAvailableTimeslotsForEvent) return;
          matchedVendors.push(v);
        });
      } catch (_) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }

      return matchedVendors.length > 0 ? matchedVendors : undefined;
    }, [
      selectedEvent,
      _vendors,
      vendorIdDateTimeslotsHashMap,
      vendorsWithTimeslotsLoading,
    ]);

    return {
      error: isError || vendorsWithTimeslotsError,
      loading: isLoading || vendorsWithTimeslotsLoading,
      vendors: availableVendors,
      selectEvent: setSelectedEvent,
      selectedEvent,
      areVendorsReady: isSelectedEventFulfilled,
    };
  };
