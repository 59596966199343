import { HttpService } from "services";

import { UploadMessageFileFunction } from "types/functions/uploadMessageFile.ts";
import { AuthorizationLevel } from "types/http";
import { generateQueryString } from "utils";

/**
 * Retrieve sendbird session token of the support user
 *
 * @throws {HttpServiceException}
 */
export const uploadMessageFile: UploadMessageFileFunction =
  async ({ file, channelUrl, isSupport = false }) => {
    try {
      const formData = new FormData();
      formData.append('images', file);
      let url = `/chat/images${generateQueryString({ channelUrl })}`;

      const { images }: { images: string[] } = await HttpService.fetch(
        url,
        {
          requestConfig: {
            method: "POST",
            params: {
              uploadToSendbird: true,
              isSupport,
            },
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            data: formData
          },
        },
        AuthorizationLevel.AUTHORIZED
      );

      return images[0];
    } catch (error) {
      throw error;
    }
  };
