import axios from "axios";
import { useState } from "react";

import { useDocumentStore } from "store";
import { SetBulkFinalDocumentHashFunction } from "types/functions";
import { BaseAsyncHook } from "types/hooks";

interface UseBulkSetFinalDocumentHash extends BaseAsyncHook {
  setBulkFinalDocumentHash: SetBulkFinalDocumentHashFunction;
}

export const useBulkSetFinalDocumentHash = (): UseBulkSetFinalDocumentHash => {
  const _storeFunc = useDocumentStore(
    (state) => state.setBulkFinalDocumentHash
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean | string>(false);

  const setBulkFinalDocumentHash = async (
    ...args: Parameters<SetBulkFinalDocumentHashFunction>
  ) => {
    try {
      setIsLoading(true);
      setIsError(false);
      const response = await _storeFunc(args[0]);
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // @ts-ignore
        const errorData = error.response?.data?.payload?.data as {
          [key: string]: string;
        };
        const errorMessage = Object.values(errorData).join(", ");
        setIsError(errorMessage);
      } else {
        setIsError(true);
      }
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error: isError,
    loading: isLoading,
    setBulkFinalDocumentHash,
  };
};
