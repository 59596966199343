import { useState } from "react";

import { useDocumentStore } from "store";
import { DownloadDocumentsFunction } from "types/functions";
import { BaseAsyncHook } from "types/hooks";

interface UseDownloadDocuments extends BaseAsyncHook {
  downloadDocuments: DownloadDocumentsFunction;
}

export const useDownloadDocuments = (): UseDownloadDocuments => {
  const _storeFunc = useDocumentStore((state) => state.downloadDocuments);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const downloadDocuments = async (...args: Parameters<DownloadDocumentsFunction>) => {
    try {
      setIsLoading(true);
      setIsError(false);
      return await _storeFunc(args[0]);
    } catch (error) {
      setIsError(true);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error: isError,
    loading: isLoading,
    downloadDocuments,
  };
};
