import { HttpService } from "services";
import { Offer } from "types/entities";

import { GetOffersFunction } from "types/functions/getOffers";
import { AuthorizationLevel } from "types/http";
import { generateQueryString } from "utils";

/**
 * Retrieve offers
 *
 * @throws {OfferServiceException}
 */
export const getOffers: GetOffersFunction = async (
  ...args: Parameters<GetOffersFunction>
) => {
  try {
    const { listingId } = args[0];
    const response: Offer[] = await HttpService.fetch(
      `/offer/${generateQueryString({ listingId })}`,
      {
        requestConfig: {
          method: "GET",
        },
      },
      AuthorizationLevel.AUTHORIZED
    );

    return response;
  } catch (error) {
    throw error;
  }
};
