import { HttpService } from "services";
import { RemovePhotoFunction } from "types/functions/removePhoto";

import { AuthorizationLevel } from "types/http";

/**
 * Set photo_urls of a given listing
 *
 * @throws {PhotoServiceException}
 */
export const removePhoto: RemovePhotoFunction = async (
  ...args: Parameters<RemovePhotoFunction>
) => {
  try {
    const { photoId } = args[0];
    const response: boolean = await HttpService.fetch(
      `/photo/${photoId}`,
      {
        requestConfig: {
          method: "DELETE",
        },
      },
      AuthorizationLevel.AUTHORIZED
    );

    return response;
  } catch (error) {
    throw error;
  }
};
