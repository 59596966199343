import { HttpService } from "services";
import { SetPhotosFunction } from "types/functions/setPhotos";

import { AuthorizationLevel } from "types/http";
import { generateQueryString } from "utils";

/**
 * Set photo_urls of a given listing
 *
 * @throws {PhotoServiceException}
 */
export const setPhotos: SetPhotosFunction = async (
  ...args: Parameters<SetPhotosFunction>
) => {
  try {
    const { listingId, fileNames } = args[0];
    const response: boolean = await HttpService.fetch(
      `/photo${generateQueryString({ listingId })}`,
      {
        requestConfig: {
          method: "PUT",
          data: { photos: fileNames },
        },
      },
      AuthorizationLevel.AUTHORIZED
    );

    return response;
  } catch (error) {
    throw error;
  }
};
