import { useEffect, useState } from "react";
import { useAppSettingsStore } from "store";

import { BaseAsyncHook } from "types/hooks";
import { AppPricesInStore } from "types/store";
import { formatAppPrices } from "utils";

interface UseGetAppPrices extends BaseAsyncHook {
  loading: boolean;
  error: boolean;
  appPrices: AppPricesInStore;
}

export const useGetAppPrices = (): UseGetAppPrices => {
  const _appPrices = useAppSettingsStore((state) => state.appPrices);
  const _getAppPrices = useAppSettingsStore((state) => state.getAppPrices);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    const onLoad = async () => {
      try {
        setIsError(false);
        await _getAppPrices();
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };
    onLoad();
  }, [_getAppPrices]);

  return {
    error: isError,
    loading: isLoading,
    appPrices: formatAppPrices(_appPrices),
  };
};
