import { useEffect, useState } from "react";

import { useListingsStore } from "store";
import { Listing } from "types/entities";
import {
  GetListingsFunction,
  GetListingsFunctionParams,
} from "types/functions";
import { BaseAsyncHook, BaseAsyncHookInput } from "types/hooks";
import { parseAsyncHookConfiguration } from "utils";

interface UseGetListings extends BaseAsyncHook {
  getListings: GetListingsFunction;
  listings: Listing[];
}

interface UseGetListingsInput
  extends BaseAsyncHookInput,
    GetListingsFunctionParams {}

export const useGetListings = (props?: UseGetListingsInput): UseGetListings => {
  const config = parseAsyncHookConfiguration(props?.configuration);

  const _listings = useListingsStore((state) => state.listings);
  const _getListings = useListingsStore((state) => state.getListings);
  const [isLoading, setIsLoading] = useState<boolean>(
    config.preventNetworkRequest ? false : true
  );
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    if (config.preventNetworkRequest === false) getListings(props);
  }, []);

  const getListings = async (...args: Parameters<GetListingsFunction>) => {
    try {
      setIsLoading(true);
      setIsError(false);
      const listings = await _getListings(...args);
      return listings;
    } catch (error) {
      setIsError(true);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error: isError,
    loading: isLoading,
    getListings,
    listings: _listings,
  };
};
