import { Typography, TypographyProps } from "@mui/material";
import React from "react";

interface OwnProps extends TypographyProps {
  children: React.ReactNode;
}

type TText = OwnProps;

export const Text: React.FC<TText> = ({ children, ...props }) => {
  return (
    <Typography color="#1c1c1c" {...props}>
      {children}
    </Typography>
  );
};
