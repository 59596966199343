import { useState } from "react";

import { useTitleStore } from "store";
import { GetTitleDocumentsFunction } from "types/functions";
import { BaseAsyncHook } from "types/hooks";

interface UseGetTitleDocuments extends BaseAsyncHook {
  getTitleDocuments: GetTitleDocumentsFunction;
}

export const useGetTitleDocuments = (): UseGetTitleDocuments => {
  const _stateFunc = useTitleStore(
    (state) => state.getTitleDocuments
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const getTitleDocuments = async (
    ...args: Parameters<GetTitleDocumentsFunction>
  ) => {
    try {
      setIsLoading(true);
      setIsError(false);
      const titleDocuments = await _stateFunc(args[0]);
      return titleDocuments;
    } catch (error) {
      setIsError(true);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error: isError,
    loading: isLoading,
    getTitleDocuments,
  };
};
