import { TableData } from "types/components/table"

export const INITIAL_LISTING_GENERIC_TABLE_DATA: TableData = {
  headers: [],
  data: [],
}

export const INITIAL_LISTINGS_TABLE_DATA: TableData = {
  headers: [
    
    {
      title: 'Address Line 1',
      key: 'address_line_1',
    },
    {
      title: 'Address Line 2',
      key: 'address_line_2',
    },
    {
      title: 'City',
      key: 'city',
    },
    {
      title: 'State',
      key: 'state',
    },
    {
      title: 'Zip',
      key: 'zip',
    },
    {
      title: 'List Price',
      key: 'list_price',
    },
    {
      title: 'Beds',
      key: 'beds',
    },
    {
      title: 'Baths',
      key: 'baths',
    },
    {
      title: 'Square Ft.',
      key: 'sqft',
    },
    {
      title: 'Lot Size(Square Ft.)',
      key: 'lot_size_sqft',
    },
    {
      title: 'Closing Date',
      key: 'closing_date',
    },
    {
      title: 'Longitude',
      key: 'longitude',
    },
    {
      title: 'Latitude',
      key: 'latitude',
    },
  ],
  data: [],
}

export const INITIAL_BULK_LISTINGS: TableData = {
  headers: [
    {
      title: '#',
      key: 'displayId'
    },
    {
      title: 'Address Line 1',
      key: 'addressLine1',
    },
    {
      title: 'Address Line 2',
      key: 'addressLine2',
    },
    {
      title: 'City',
      key: 'city',
    },
    {
      title: 'State',
      key: 'state',
    },
    {
      title: 'Zip',
      key: 'zip',
    },
    {
      title: 'List Price',
      key: 'listPrice',
    },
    {
      title: 'Beds',
      key: 'numBeds',
    },
    {
      title: 'Baths',
      key: 'numBaths',
    },
    {
      title: 'Square Ft.',
      key: 'sqft',
    },
    {
      title: 'Lot Size(sq. ft)',
      key: 'lotSizeSqft',
    },
    {
      title: 'Closing Date',
      key: 'closingDate',
    },
    {
      title: 'Longitude',
      key: 'longitude',
    },
    {
      title: 'Latitude',
      key: 'latitude',
    },
  ],
  data: [],
}

export const INITIAL_LISTING_DETAILS_TABLE_DATA: TableData = {
  headers: [
    {
      title: 'ID',
      key: 'id',
    },
    {
      title: 'Seller ID',
      key: 'seller_id',
    },
    {
      title: 'Awaiting Approval',
      key: 'awaiting_approval',
    },
    {
      title: 'Approved',
      key: 'approved',
    },
    {
      title: 'Address Line 1',
      key: 'address_line_1',
    },
    {
      title: 'Address Line 2',
      key: 'address_line_2',
    },
    {
      title: 'City',
      key: 'city',
    },
    {
      title: 'State',
      key: 'state',
    },
    {
      title: 'Zip',
      key: 'zip',
    },
    {
      title: 'List Price',
      key: 'list_price',
    },
    {
      title: 'Beds',
      key: 'beds',
    },
    {
      title: 'Baths',
      key: 'baths',
    },
    {
      title: 'Square Ft.',
      key: 'sqft',
    },
    {
      title: 'Lot Size(Sq. Ft)',
      key: 'lot_size_sqft',
    },
    {
      title: 'Description',
      key: 'description',
    },
    {
      title: 'Home Type',
      key: 'home_type',
    },
    {
      title: 'Pool',
      key: 'pool',
    },
    {
      title: 'AC',
      key: 'has_ac',
    },
    {
      title: 'Year built',
      key: 'year_built',
    },
    {
      title: 'Availability',
      key: 'availability',
    },
    {
      title: 'Corelogic ID',
      key: 'corelogic_id',
    },
    {
      title: 'Listing Date',
      key: 'listing_date',
    },
    {
      title: 'Occupied',
      key: 'occupied',
    },
    {
      title: 'Last Date of Occupancy',
      key: 'last_date_of_occupancy',
    },
    {
      title: 'Roof Age',
      key: 'roof_age',
    },
    {
      title: 'Roof Type',
      key: 'roof_type',
    },
    {
      title: 'Closing Date',
      key: 'closing_date',
    },
    {
      title: 'Coordinates',
      key: 'coordinates',
    },
    {
      title: 'Is Pending',
      key: 'is_pending',
    },
  ],
  data: [],
}

export const INITIAL_LISTING_EVENTS_TABLE_DATA: TableData = {
  headers: [
    {
      title: 'Status',
      key: 'status',
    },
    {
      title: 'Type',
      key: 'type',
    },
    {
      title: 'Date',
      key: 'date_specific',
    },
    {
      title: 'Start',
      key: 'minutes_start',
    },
    {
      title: 'End',
      key: 'minutes_end',
    },
    {
      title: 'Inviter',
      key: 'inviter',
    },
    {
      title: 'Invitee',
      key: 'invitee',
    },
    {
      title: 'Feedback Created',
      key: 'feedback_created',
    },
    {
      title: 'Lockbox Access',
      key: 'lockbox_accessed_at',
    },
  ],
  data: [],
}

export const INITIAL_LISTING_OFFERS_TABLE_DATA: TableData = {
  headers: [
    {
      title: 'User',
      key: 'user',
    },
    {
      title: 'Offer Amount',
      key: 'offer_amount',
    },
    {
      title: 'Cash Payment',
      key: 'cash_payment',
    },
    {
      title: 'Financing',
      key: 'financing',
    },
    {
      title: 'Closing Date',
      key: 'closing_date',
    },
    {
      title: 'Buyer Approval',
      key: 'buyer_approval',
    },
    {
      title: 'Status',
      key: 'status',
    },
    {
      title: 'FHA VA Provision',
      key: 'fha_va_provision',
    },
    {
      title: 'Earnest Money Termination Period',
      key: 'earnest_money_termination_period',
    },
    {
      title: 'Last Update',
      key: 'last_update_date',
    },
    {
      title: 'Offer Document Hash',
      key: 'offer_document_hash',
    },
    {
      title: 'Financing Document Hash',
      key: 'financing_document_hash',
    },
  ],
  data: [],
}

export const INITIAL_LISTING_INSPECTION_DOCS_TABLE_DATA: TableData = {
  headers: [
    {
      title: 'File Name',
      key: 'file_name',
    },
  ],
  data: [],
}
export const INITIAL_LISTING_EVERSIGN_DOCS_TABLE_DATA: TableData = {
  headers: [
    {
      title: 'Type',
      key: 'type',
    },
    {
      title: 'Link',
      key: 'link',
    },
  ],
  data: [],
}


