import React from "react";
import { FormControl, Stack } from "@mui/material";

import { Button, Select, Table } from "components/common";
import {
  useGenerateTableData,
  useGetListings,
  useGetEvents,
  useGetAvailableVendorsForEvent,
  useAssignVendorToEvent,
} from "hooks";
import { DateFormatter } from "utils";
import { EventStatus } from "types/entities";
import { ViewWrapper } from "components/wrappers";

export const VendorEvents: React.FC = () => {
  const { events, loading: eventsLoading } = useGetEvents();
  const { listings, loading: listingsLoading } = useGetListings();
  const { vendors, selectedEvent, selectEvent, areVendorsReady } =
    useGetAvailableVendorsForEvent();
  const {
    assignVendorToEvent,
    canPerformAssignment,
    selectEvent: selectEventForAssignment,
    selectVendor,
    loading: assignVendorToEventLoading,
    selectedVendorId,
  } = useAssignVendorToEvent();

  const isEventHttpRequestInProgress = eventsLoading || listingsLoading;

  const resetVendorSelection = () => {
    selectEventForAssignment(undefined);
    selectVendor(undefined);
  };

  const eventsTableData = useGenerateTableData({
    columnNames: [
      "UID",
      "Date",
      "Start",
      "End",
      "Type",
      "Status",
      "Address Line 1",
      "Address Line 2",
      "City",
      "Zip",
    ],
    data: !isEventHttpRequestInProgress
      ? events.map((event) => {
          // Find listing for this event. (@TODO: hash map/table for listing<->event would be much more convinient and faster)

          // Note that at this point in time listings may not be fetched yet (even if protected with isHttpRequestInProgress).
          const listing = listings.find((l) => l.id === event.listing_id);

          return {
            visible: [
              event.id,
              DateFormatter.format(event.date_specific),
              DateFormatter.numberToTimeString(event.minutes_start),
              DateFormatter.numberToTimeString(event.minutes_end),
              event.type,
              event.status,
              listing ? listing.address_line_1 : "N/A",
              listing ? listing.address_line_2 : "N/A",
              listing ? listing.city : "N/A",
              listing ? listing.zip : "N/A",
            ],
            hidden: [
              {
                name: "_uid",
                value: event.id,
              },
            ],
          };
        })
      : [],
    onRowClick: (event) => {
      if (event.status !== EventStatus.PENDING) return;
      // Forget about selected Event and Vendor when selected Event changes.
      resetVendorSelection();
      if (selectedEvent && event.uid === selectedEvent.id) {
        // Unselect event.
        selectEvent(undefined);
      } else {
        if (event.uid) {
          // Get vendors for selected event.
          const selectedEvent = events.find((e) => e.id === event.uid);
          selectEvent(selectedEvent);
          selectEventForAssignment(selectedEvent?.id);
        }
      }
    },
  });

  const handleAssigningVendorToEvent = async () => {
    await assignVendorToEvent();
  };

  if (isEventHttpRequestInProgress === true) return null;

  return (
    <ViewWrapper title="Assign vendors to events">
      <Stack>
        <Table
          onRowClick={eventsTableData.onRowClick}
          tableData={{
            data: eventsTableData.data,
            headers: eventsTableData.headers,
          }}
          dataIdKey="_uid"
          selectedRowId={selectedEvent ? selectedEvent.id : undefined}
        />
        {areVendorsReady && vendors ? (
          <Stack marginTop="20px" spacing="20px">
            <FormControl>
              <Select
                options={vendors.map((v) => ({
                  label: `${v.givenName} ${v.familyName}`,
                  value: v.id,
                }))}
                value={selectedVendorId ?? ""}
                label="Pick a vendor"
                onChange={selectVendor}
              />
            </FormControl>
            <Button
              variant="contained"
              type="submit"
              disabled={!canPerformAssignment || assignVendorToEventLoading}
              onClick={async () => {
                await handleAssigningVendorToEvent();
              }}
            >
              Select
            </Button>
          </Stack>
        ) : null}
      </Stack>
    </ViewWrapper>
  );
};
