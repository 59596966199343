import { HttpService } from "services";
import { ApiUser } from "types/api";
import { User } from "types/entities";
import { GetUsersFunction } from "types/functions";
import { AuthorizationLevel } from "types/http";

/**
 * Retrieve users of the app.
 *
 * @throws {UserServiceException}
 */
export const getUsers = async (...args: Parameters<GetUsersFunction>): Promise<User[]> => {
  try {
    const users = await HttpService.fetch<ApiUser[]>(
      "/user",
      {
        requestConfig: {
          method: "GET",
          params: {
            ...args[0],
          }
        },
      },
      AuthorizationLevel.AUTHORIZED
    );

    return users.map((user) => HttpService.Serializer.serializeUser(user));
  } catch (error) {
    throw error;
  }
};
