/**
 * Possible authorization levels used for making requests.
 * In other words, a way to denote whether client should attach access token to an HTTP header
 */
export enum AuthorizationLevel {
  // Do not pass access token, we assume that identity is unknown.
  UNAUTHORIZED = "UNAUTHORIZED",
  // Pass access token, if stale try to refresh. If cannot refresh do not allow requests.
  AUTHORIZED = "AUTHORIZED",
  // If access token exist or is refreshable - attach; otherwise make a request as unauthorized client. In other words, use this for making requests to endpoints that let both unauthorized and authorized clients (but if client is authorized they leverage this knowlegde in some way).
  ANY = "ANY",
}
