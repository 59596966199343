import React, { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

interface OwnProps {
  url?: string;
  base64?: string;
}

export const PDFViewer: React.FC<OwnProps> = ({ url = '', base64 = '' }) => {
  const [numPages, setNumPages] = useState<null | number>(null);

  function onDocumentLoadSuccess({ numPages }: { numPages: number}) {
    setNumPages(numPages);
  }

  return (
    <div>
      <Document file={url || base64} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1}/>
        ))}
      </Document>
    </div>
  )
}
