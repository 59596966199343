import { HttpService } from "services";

import { RemoveListingFunction, RemoveListingFunctionParams } from "types/functions";
import { AuthorizationLevel } from "types/http";

/**
 * Remove a listing
 * 
 * @throws {HttpServiceException}
 */
export const removeListing: RemoveListingFunction = async (
  params: RemoveListingFunctionParams
) => {
  try {
    const { listingId } = params;
    let url = `/listing/${listingId}`;
    
    const res: boolean = await HttpService.fetch(
      url,
      {
        requestConfig: {
          method: "DELETE",
        },
      },
      AuthorizationLevel.AUTHORIZED
    );

    return res;
  } catch (error) {
    throw error;
  }
};
