/**
 * @file HTTP requests logic. Refreshing access and refresh tokens.
 */

import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import { TokensManager } from "services/HttpService";
import { isJwtExpired } from "utils";
import { AuthenticationTokens, AuthorizationLevel } from "types/http";

// Base API url on module import
const apiUrl =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PROD_API
    : process.env.REACT_APP_DEV_API;

type fetchArgs = {
  requestConfig: AxiosRequestConfig;
};

/**
 * Get current state of client tokens. If one of the tokens is undefined do not return any of them to denote that client isn't capable of making authorized requests.
 *
 * Implements token refreshing logic.
 */
const tokensHealthcheck = async (): Promise<
  AuthenticationTokens | undefined
> => {
  try {
    let accessToken = TokensManager.getAccessToken();
    let refreshToken = TokensManager.getRefreshToken();

    if (!accessToken || isJwtExpired(accessToken)) {
      // Try to refresh token
      const refreshResponse = await unathFetch(`${apiUrl}/auth/refresh`, {
        requestConfig: {
          method: "POST",
          withCredentials: true,
          data: {
            refresh_token: refreshToken,
          }
        },
      })

      if (refreshResponse.status >= 400) return undefined;

      // extract tokens from response and reassign
      const { access_token, refresh_token } = refreshResponse.data;

      await Promise.all([TokensManager.setAccessToken(access_token), TokensManager.setRefreshToken(refresh_token)]);

      accessToken = access_token as string;
    }

    return {
      accessToken,
    };
  } catch (error) {
    throw error;
  }
};

// For unprotected routes
const unathFetch = async (
  url: string,
  args: fetchArgs
): Promise<AxiosResponse> => {
  try {
    const { requestConfig: requestLibraryConfig } = args;

    let config: typeof requestLibraryConfig = {
      ...requestLibraryConfig,
      withCredentials: true,
    };

    const response = await axios(url, config);

    return response;
  } catch (error) {
    throw error;
  }
};

// For protected routes
const authFetch = async (
  url: string,
  args: fetchArgs
): Promise<AxiosResponse> => {
  try {
    const { requestConfig: requestLibraryConfig } = args;

    let config = requestLibraryConfig;

    const tokens = await tokensHealthcheck();

    if (tokens === undefined)
      throw new Error("authFetch: Cannot obtain new tokens");

    // Attach access token to the request
    config = {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${tokens.accessToken}`,
      },
      withCredentials: true,
    };

    const response = await axios(url, config);

    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * Helper function that wrapps fetching logic implemented with Axios.
 *
 * Takes generic to denote type returned from request.
 *
 * @param {fetchArgs} args
 *  */
export const fetch = async <RES>(
  path: string,
  args: fetchArgs,
  authorizationLevel: AuthorizationLevel = AuthorizationLevel.UNAUTHORIZED
): Promise<RES> => {
  const url = `${apiUrl}${path}`;

  try {
    const response = await (async () => {
      if (authorizationLevel === AuthorizationLevel.UNAUTHORIZED) {
        return await unathFetch(url, args);
      } else if (authorizationLevel === AuthorizationLevel.AUTHORIZED) {
        return await authFetch(url, args);
      } else if (authorizationLevel === AuthorizationLevel.ANY) {
        // Check if access token ok
        const tokens = await tokensHealthcheck();

        if (tokens === undefined) {
          return await unathFetch(url, args);
        } else {
          return await authFetch(url, args);
        }
      } else {
        // Fallback to unauth fetch for preventing an error
        console.warn(
          "fetch: unhandled AuthorizationLevel. Fallback to UNAUTHORIZED fetch"
        );
        return await unathFetch(url, args);
      }
    })();

    // Throw an error in spite of above fallback just to be cautious
    if (response === undefined)
      throw new Error("fetch: unknown AuthorizationLevel");

    if (response.status >= 400) throw new Error(response.statusText);

    return response.data as RES;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
