import create from "zustand";

import { ChatService } from "services";
import { SendbirdToken } from "types/entities";

interface ChatStore {
  supportToken: undefined | SendbirdToken;
  getSendbirdSupportToken: () => Promise<SendbirdToken | undefined>;
}

export const useChatStore = create<ChatStore>((set, get) => ({
  supportToken: undefined,
  getSendbirdSupportToken: async () => {
    try {
      const supportToken = await ChatService.getSendbirdSupportToken();
      set({ supportToken });
      return supportToken;
    } catch (error) {
      throw error;
    }
  },
}));
