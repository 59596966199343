import { EventType, VendorRole } from "types/entities";

export const matchEventTypeWithVendorRole = (
  eventType: EventType
): VendorRole => {
  switch (eventType) {
    case EventType.INSPECTION:
      return VendorRole.INSPECTOR;
    case EventType.PHOTOSHOOT:
      return VendorRole.PHOTOGRAPHER;
    default:
      throw new Error("Unhandled event type");
  }
};
