import { AuthorizationLevel } from "types/http";
import { HttpService } from "services";
import { GetVendorsFunction } from "types/functions";
import { ApiUser } from "types/api";
import { generateQueryString } from "utils";

/**
 * Retrieve vendor users.
 *
 * @throws {UserServiceException}
 */
export const getVendors: GetVendorsFunction = async (
  ...args: Parameters<GetVendorsFunction>
) => {
  try {
    const response = await HttpService.fetch<ApiUser[]>(
      `/user${generateQueryString({ isVendor: 'true' })}`,
      {
        requestConfig: {
          method: "GET",
        },
      },
      AuthorizationLevel.AUTHORIZED
    );

    return response.map((user) => HttpService.Serializer.serializeUser(user));
  } catch (error) {
    throw error;
  }
};
