import { HttpService } from "services";

import { SubmitInspectionDocsFunction, SubmitInspectionDocsParams } from "types/functions";
import { AuthorizationLevel } from "types/http";
import { generateQueryString } from "utils";

/**
 * Mark selected documents as inspection documents
 * 
 * @throws {HttpServiceException}
 */
export const submitInspectionDocs: SubmitInspectionDocsFunction = async (
  params: SubmitInspectionDocsParams
) => {
  try {
    const { listingId, documents } = params;
    let url = `/document/inspection-documents${generateQueryString({ listingId })}`;
    
    await HttpService.fetch(
      url,
      {
        requestConfig: {
          method: "PUT",
          data: { documents }, 
        },
      },
      AuthorizationLevel.AUTHORIZED
    );

    return true;
  } catch (error) {
    throw error;
  }
};
