import { useEffect, useState } from "react";

import { useUserStore } from "store";
import { BaseAsyncHook } from "types/hooks";
import { UserInStore } from "types/store";

interface UseGetMe extends BaseAsyncHook {
  user: UserInStore;
  loading: boolean;
  error: boolean;
}

export const useGetMe = (): UseGetMe => {
  const storeUser = useUserStore((state) => state.authorizedUser);
  const getMe = useUserStore((state) => state.getMe);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    const onLoad = async () => {
      try {
        setIsError(false);
        await getMe();
      } catch (error) {
        setIsError(true);
      }

      setIsLoading(false);
    };
    onLoad();
  }, [getMe]);

  return {
    error: isError,
    loading: isLoading,
    user: storeUser,
  };
};
