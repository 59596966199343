import { HttpService } from "services";
import { VerifyResetCodeFunction } from "types/functions";
import { AuthorizationLevel } from "types/http";

/**
 *
 *
 * @throws {UserServiceException}
 */
export const verifyResetCode: VerifyResetCodeFunction = async (
  ...args: Parameters<VerifyResetCodeFunction>
): Promise<boolean> => {
  try {
    const {code, email} = args[0];
    const result = await HttpService.fetch<{ isValid: boolean }>(
      "/auth/verify-reset-code",
      {
        requestConfig: {
          method: "POST",
          data: {
            email,
            code,
          },
        },
      },
      AuthorizationLevel.UNAUTHORIZED
    );

    return result.isValid;
  } catch (error) {
    throw error;
  }
};
