import { AppPriceType } from "types/entities";
import { AppPricesInStore } from "types/store";

export const formatAppPrices = (prices: AppPricesInStore): AppPricesInStore => {
  if (!prices) return undefined;
  //@ts-ignore
  const formattedPrices: AppPrices = structuredClone(prices); // Break nested objects reference chain
  (Object.keys(prices) as AppPriceType[]).forEach((priceType) => {
    formattedPrices[priceType]!["price"] = prices[priceType]!["price"] / 100;
  });
  return formattedPrices;
};
