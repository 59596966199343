import { Box, Stack } from "@mui/material";
import { notification } from "antd";
import { Button, ErrorMessage, InputField } from "components/common";
import { ViewWrapper } from "components/wrappers";
import { useGetMe, useSetUserPassword } from "hooks";
import { useEffect, useState } from "react";

interface Form {
  email: string;
  password: string;
  oldPassword: string;
}

const INITIAL_FORM: Form = {
  email: "",
  password: "",
  oldPassword: "",
};

export const ChangePassword: React.FC = () => {
  const { user } = useGetMe();
  const { error, setUserPassword } = useSetUserPassword();
  const [success, setSuccess] = useState(false);
  const [form, setForm] = useState({ ...INITIAL_FORM });
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    if (user)
      setForm({
        ...INITIAL_FORM,
        email: user?.emailAddress,
      });
  }, [user]);

  const handleFormChange = (key: keyof Form, val: string) => {
    setForm({
      ...form,
      [key]: val,
    });
  };

  const handleSetPassword = async () => {
    setSuccess(await setUserPassword(form));
    api.success({
      message: `Successfully changed password`,
      placement: "bottomRight",
    });
  };

  const isButtonDisabled = () => {
    return (
      !form.email ||
      !form.password ||
      !form.oldPassword ||
      !confirmedPassword ||
      confirmedPassword !== form.password
    );
  };

  return (
    <Box
      // Handle styling in unauthenticated mode(not wrapped by <AuthenticatedView>)
      sx={
        user
          ? {}
          : {
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }
      }
    >
      {contextHolder}
      <ViewWrapper title="Update password">
        <Stack spacing={2}>
          <InputField
            fullWidth
            label="Email"
            type="email"
            value={form.email}
            onChange={(text) => handleFormChange("email", text)}
          />
          <InputField
            fullWidth
            label="Previous password"
            type="password"
            value={form.oldPassword}
            onChange={(text) => handleFormChange("oldPassword", text)}
          />
          <InputField
            fullWidth
            label="New password"
            type="password"
            value={form.password}
            onChange={(text) => handleFormChange("password", text)}
          />
          <InputField
            fullWidth
            label="Confirm password"
            type="password"
            value={confirmedPassword}
            onChange={(text) => setConfirmedPassword(text)}
          />
          {confirmedPassword !== form.password && (
            <ErrorMessage descriptionText="Passwords do not match" />
          )}
          {error && <ErrorMessage />}
          {success && (
            <ErrorMessage
              titleText="Success"
              alertProps={{ severity: "success" }}
              descriptionText={"Password has been successfully set"}
            />
          )}
          <Button
            variant="contained"
            fullWidth
            onClick={handleSetPassword}
            disabled={isButtonDisabled()}
          >
            Confirm
          </Button>
        </Stack>
      </ViewWrapper>
    </Box>
  );
};
