import { Alert, AlertProps, AlertTitle, AlertTitleProps } from "@mui/material";

interface OwnProps {
  alertProps?: AlertProps;
  alertTitleProps?: AlertTitleProps;
  titleText?: string;
  descriptionText?: string;
}

export const ErrorMessage: React.FC<OwnProps> = ({
  alertProps,
  alertTitleProps,
  titleText = "Error",
  descriptionText = "Something went wrong",
}) => {
  return (
    <Alert severity="error" {...alertProps}>
      <AlertTitle {...alertTitleProps}>{titleText}</AlertTitle>
      {descriptionText}
    </Alert>
  );
};
