import { HttpService } from "services";
import { EndpointTitleStatus } from "types/entities";
import { GetTitleStatusFunctionParams, GetTitleStatusFunction } from "types/functions";
import { AuthorizationLevel } from "types/http";
import { generateQueryString } from "utils";

/**
 * Retrieve title status
 * 
 * @throws {HttpServiceException}
 */
export const getTitleStatus: GetTitleStatusFunction = async (
  params: GetTitleStatusFunctionParams
) => {
  try {
    const { listingId, transactionId } = params;
    let url = `/title/${transactionId}/status${generateQueryString({ listingId })}`;
    
    const res: EndpointTitleStatus = await HttpService.fetch(
      url,
      {
        requestConfig: {
          method: "GET",
        },
      },
      AuthorizationLevel.AUTHORIZED
    );

    return res;
  } catch (error) {
    throw error;
  }
};
