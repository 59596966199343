import { HttpService } from "services";
import { ListingDocument } from "types/entities";

import { DownloadDocumentsFunction, DownloadDocumentsParams } from "types/functions";
import { AuthorizationLevel } from "types/http";
import { generateQueryString } from "utils";

/**
 * @TODO: add valid comment
 * 
 * @throws {HttpServiceException}
 */
export const downloadDocuments: DownloadDocumentsFunction = async (
  params: DownloadDocumentsParams
) => {
  try {
    const { listingId, signal, type } = params;
    let url = `/document/download${generateQueryString({ listingId, type })}`;
    
    const res: ListingDocument[] = await HttpService.fetch(
      url,
      {
        requestConfig: {
          method: "GET",
          signal,
        },
      },
      AuthorizationLevel.AUTHORIZED
    );

    return res;
  } catch (error) {
    throw error;
  }
};
