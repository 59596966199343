import _ from "lodash";

import { HttpService } from "services";
import { ApiTimeslot } from "types/api";
import { GetVendorAvailableTimeslotsFunction } from "types/functions";
import { AuthorizationLevel } from "types/http";

/**
 * Get Vendors' free time slots on a given date.
 * 
 * @NOTE: Currently, calling side can't specify event type
 *
 * @throws {UserServiceException}
 */
export const getVendorAvailableTimeslots: GetVendorAvailableTimeslotsFunction =
  async (
    ...[{ dateSpecific }]: Parameters<GetVendorAvailableTimeslotsFunction>
  ) => {
    try {
      const response = await HttpService.fetch<ApiTimeslot[]>(
        `/user/availability/timeslots/photo-shoot?${new URLSearchParams({
          dateSpecific: dateSpecific,
        })}`,
        {
          requestConfig: {
            method: "GET",
          },
        },
        AuthorizationLevel.AUTHORIZED
      );

      return response;
    } catch (error) {
      throw error;
    }
  };
