// List of tab keys that contain boolean and boolean's description
export const TABS_WITH_BOOLEAN_DESCRIPTION: readonly string[] = [
  "floodInfo",
  "moreInfo",
  "defects",
];

// List of fields' key that are of type `Date`
export const DATE_FIELDS: readonly string[] = [
  "closingDate",
  "lastDateOfOccupancy",
  "listingDate",
];
