import { TableData } from "types/components";
import { objectToTableDataItem } from "../objectToTableDataItem";

/**
 * Select keys from `objects` basing on `currentTable`'s headers.
 * Then create new `TableData` with new objects appended.
 *
 * Also, enables creating emphemeral `id` property that `Table` will
 * use to distinguish its members in case objects don't have their
 * own `id` property.
 */
export const appendToTableData = <A extends Record<string, unknown>>(
  currentTable: TableData,
  objects: A[],
  generateEphemeralId: (idx: number, object?: A) => string = (idx: number) =>
    idx.toString()
): TableData => {
  const keys = currentTable.headers.map((h) => h.key);
  const objectsAsTableItems = objects.map((o, i) => ({
    id: generateEphemeralId(i, o),
    ...objectToTableDataItem(keys, o),
  }));
  return {
    ...currentTable,
    data: [...currentTable.data, ...objectsAsTableItems],
  };
};
