import { useState } from "react";

import { useListingsStore } from "store";
import { GetBulkGroupsFunction } from "types/functions";
import { BaseAsyncHook } from "types/hooks";

interface UseGetBulkGroups extends BaseAsyncHook {
  getBulkGroups: GetBulkGroupsFunction;
}

export const useGetBulkGroups = (): UseGetBulkGroups => {
  const _storeFunc = useListingsStore((store) => store.getBulkGroups);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const getBulkGroups = async () => {
    try {
      setIsLoading(true);
      setIsError(false);
      const groups = await _storeFunc();
      return groups;
    } catch (error) {
      setIsError(true);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error: isError,
    loading: isLoading,
    getBulkGroups,
  };
};
