import { useState } from "react";

import { useUserStore } from "store";
import { GetUsersFunction, GetUsersFunctionParams } from "types/functions";
import { BaseAsyncHook } from "types/hooks";

type PredefinedUserTypes = Record<string, GetUsersFunctionParams>;

interface UseGetUsers extends BaseAsyncHook {
  getUsers: GetUsersFunction;
  USER_TYPES: PredefinedUserTypes;
}

const USER_TYPES: PredefinedUserTypes = {
  PLAIN_USER: {
    isAdmin: false,
    isInspector: false,
    isListingSpecialist: false,
    isPhotographer: false,
    isVendor: false,
  },
};

export const useGetUsers = (): UseGetUsers => {
  const _storeFunc = useUserStore((state) => state.getUsers);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean | string>(false);

  const getUsers = async (...args: Parameters<GetUsersFunction>) => {
    try {
      setIsLoading(true);
      setIsError(false);
      const response = await _storeFunc(args[0]);
      return response;
    } catch (error: any) {
      setIsError(error.response.data.payload.message ?? true);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error: isError,
    loading: isLoading,
    getUsers,
    USER_TYPES,
  };
};
