import { HttpService } from "services";
import { Listing } from "types/entities";
import {
  BulkApproveListingsFunction,
  BulkApproveListingsFunctionParams,
} from "types/functions";
import { AuthorizationLevel } from "types/http";

/**
 * ...
 *
 * @throws {HttpServiceException}
 */
export const bulkApproveListings: BulkApproveListingsFunction = async (
  params: BulkApproveListingsFunctionParams
) => {
  try {
    const { groupId } = params;

    return await HttpService.fetch<Listing[]>(
      `/listing/bulk/${groupId}/accept`,
      {
        requestConfig: {
          method: "POST",
        },
      },
      AuthorizationLevel.AUTHORIZED
    );
  } catch (error) {
    throw error;
  }
};
