import { useState } from "react";

import { useDocumentStore } from "store";
import { GetDocumentsFunction } from "types/functions";
import { BaseAsyncHook } from "types/hooks";

interface UseGetDocuments extends BaseAsyncHook {
  getDocuments: GetDocumentsFunction;
}

export const useGetDocuments = (): UseGetDocuments => {
  const _storeFunc = useDocumentStore((state) => state.getDocuments);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const getDocuments = async (...args: Parameters<GetDocumentsFunction>) => {
    try {
      setIsLoading(true);
      setIsError(false);
      return await _storeFunc(args[0]);
    } catch (error) {
      setIsError(true);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error: isError,
    loading: isLoading,
    getDocuments,
  };
};
