import { useState } from "react";

import { useDocumentStore } from "store";
import { SubmitInspectionDocsFunction } from "types/functions";
import { BaseAsyncHook } from "types/hooks";

interface UseSubmitInspectionDocs extends BaseAsyncHook {
  submitInspectionDocs: SubmitInspectionDocsFunction;
}

export const useSubmitInspectionDocs = (): UseSubmitInspectionDocs => {
  const _storeFunc = useDocumentStore((state) => state.submitInspectionDocs);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const submitInspectionDocs = async (...args: Parameters<SubmitInspectionDocsFunction>) => {
    try {
      setIsLoading(true);
      setIsError(false);
      await _storeFunc(args[0]);
      return true;
    } catch (error) {
      setIsError(true);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error: isError,
    loading: isLoading,
    submitInspectionDocs,
  };
};
