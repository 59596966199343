import { HttpService } from "services";
import { ResetPasswordFunction } from "types/functions";
import { AuthorizationLevel } from "types/http";

/**
 *
 *
 * @throws {UserServiceException}
 */
export const resetPassword: ResetPasswordFunction = async (
  ...args: Parameters<ResetPasswordFunction>
): Promise<void> => {
  try {
    const [email, password, code] = args;
    await HttpService.fetch(
      "/auth/set-password-using-reset-code",
      {
        requestConfig: {
          method: "POST",
          data: {
            email,
            password,
            code,
          },
        },
      },
      AuthorizationLevel.UNAUTHORIZED
    );
  } catch (error) {
    throw error;
  }
};
