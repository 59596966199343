import { useState } from "react";

import { useGetVendorsWithZipcodes } from "hooks";
import { BaseAsyncHook } from "types/hooks";

type GetZipcodesFunction = (
  vendorId: string | undefined
) => number[] | undefined;

interface UseGetVendorZipcodes extends BaseAsyncHook {
  getZipcodes: GetZipcodesFunction;
}

/**
 * Hook for retriveing vendor zipcodes by ID.
 *
 */
export const useGetVendorZipcodes = (): UseGetVendorZipcodes => {
  const { vendors } = useGetVendorsWithZipcodes({
    preventNetworkRequest: true,
  });
  const [isError] = useState<boolean>(false);

  // @TODO: useCallback \/
  const _getZipcodes = (
    ...args: Parameters<GetZipcodesFunction>
  ): ReturnType<GetZipcodesFunction> => {
    const [vendorId] = args;

    if (vendorId === undefined) return undefined;

    // No vendors === no zipcodes
    if (vendors === undefined) return [];

    const vendor = vendors.find((v) => v.id === vendorId);

    /* 
        No such vendor in store, no zipcodes.
        This should not happen (because vendorId comes from the store itself), print a warning for a developer
    */
    if (!vendor) {
      console.warn("No Vendor with given ID was found");
      return [];
    }

    return vendor.zipcodes ?? [];
  };

  return {
    loading: false,
    error: isError,
    getZipcodes: _getZipcodes,
  };
};
