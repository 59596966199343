import React, { FormEvent, useEffect, useState } from "react";
import { Box, Link, Stack } from "@mui/material";

import { InputField, Table, Text, Button } from "components/common";
import {
  useGenerateTableData,
  useGetVendorsWithZipcodes,
  useGetVendorZipcodes,
  useSetVendorZipcodes,
} from "hooks";
import { ViewWrapper } from "components/wrappers";
import { notification } from "antd";

export const VendorZipcodes: React.FC = () => {
  const { vendors, loading: vendorsLoading } = useGetVendorsWithZipcodes();
  const { getZipcodes } = useGetVendorZipcodes();
  const { setVendorZipcodes, loading: isSettingZipcodes } =
    useSetVendorZipcodes();

  const vendorsTableData = useGenerateTableData({
    columnNames: ["Name", "Surname", "Email", "City", "State", "Phone number"],
    data: vendors
      ? vendors.map((vendor) => ({
          hidden: [
            {
              name: "_zipcodes",
              value: vendor.zipcodes ?? [],
            },
            {
              name: "_id",
              value: vendor.id,
            },
          ],
          visible: [
            vendor.givenName,
            vendor.familyName,
            vendor.emailAddress,
            vendor.city,
            vendor.state,
            "N/A",
          ],
        }))
      : [],
    onRowClick: ({ _id }) => {
      if (_id && typeof _id === "string") {
        setChoosenVendor(_id);
      } else {
        console.warn(
          "Unable to handle row click in table, _id of Vendor is not present."
        );
      }
    },
  });

  const [choosenVendor, setChoosenVendor] = useState<string | undefined>();
  const [zipcodesString, setZipcodesString] = useState<string>("");
  const [api, contextHolder] = notification.useNotification();

  // @TODO: move into effect in future, this shouldn;t retrieve new zipcodes everytime this view rerenders
  const zipcodes = getZipcodes(choosenVendor);

  useEffect(() => {
    // Change zipcodes array to string for text input to be able to display zipcodes.
    setZipcodesString(zipcodes ? zipcodes.join(" ") : "");
  }, [zipcodes, setZipcodesString]);

  if (vendorsLoading || !vendors) return null;

  const handleSubmitZipcodes = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!choosenVendor) {
      console.warn("No chosen vendor while submitting the form");
      return;
    }

    setVendorZipcodes(
      choosenVendor,
      // Text input format to array of number
      zipcodesString.split(" ").map((z) => parseInt(z))
    );
    api.success({
      message: `New zipcodes set`,
      placement: "bottomRight",
    });
  };

  return (
    <ViewWrapper title="Assign vendors to zipcodes">
      {contextHolder}
      <Stack>
        <Text variant="body1" marginBottom="10px">
          Pick a Vendor
        </Text>
        <Table
          onRowClick={vendorsTableData.onRowClick}
          tableData={{
            data: vendorsTableData.data,
            headers: vendorsTableData.headers,
          }}
          selectedRowId={choosenVendor ?? undefined}
          dataIdKey="_id"
        />
        {zipcodes ? (
          <Box
            sx={{
              marginTop: "20px",
            }}
          >
            <form onSubmit={handleSubmitZipcodes}>
              <Stack spacing={1} sx={{ marginBottom: "15px" }}>
                <Link
                  href="https://www.unitedstateszipcodes.org/zip-code-radius-map.php"
                  underline="always"
                  fontWeight={500}
                  target="_blank"
                  rel="noreferrer"
                >
                  Tool for retrieving ZIP codes in a given radius
                </Link>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Text
                    fontWeight="500"
                    fontSize={14}
                    sx={{ marginRight: "4px", opacity: 0.9 }}
                  >
                    Please make sure the zipcodes are provided in the following
                    format:
                  </Text>
                  <Text fontSize={14}>12345 54321 34345</Text>
                </Box>
              </Stack>
              <Stack spacing={2}>
                <InputField
                  label="Zipcodes"
                  variant="outlined"
                  value={zipcodesString}
                  onChange={setZipcodesString}
                />
                <Button
                  variant="contained"
                  type="submit"
                  disabled={isSettingZipcodes}
                >
                  Update
                </Button>
              </Stack>
            </form>
          </Box>
        ) : null}
      </Stack>
    </ViewWrapper>
  );
};
