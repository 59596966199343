import React from "react";
import { Link as RouterLink } from "react-router-dom";

interface OwnProps {
  children: React.ReactNode;
  path: string;
}

type TLink = OwnProps;

export const Link: React.FC<TLink> = ({ children, path }) => {
  return <RouterLink to={path}>{children}</RouterLink>;
};
