import { useState } from "react";

import { useUserStore } from "store";
import { GetVendorAvailableTimeslotsFunction } from "types/functions";
import { BaseAsyncHook } from "types/hooks";

interface UseGetVendorAvailableTimeslots extends BaseAsyncHook {
  getVendorAvailableTimeslots: GetVendorAvailableTimeslotsFunction;
  clearError: Function;
}

export const useGetVendorAvailableTimeslots =
  (): UseGetVendorAvailableTimeslots => {
    const _storeFunc = useUserStore(
      (state) => state.getVendorAvailableTimeslots
    );
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false);

    const getVendorAvailableTimeslots = async (
      ...args: Parameters<GetVendorAvailableTimeslotsFunction>
    ) => {
      try {
        setIsLoading(true);
        setIsError(false);
        const response = await _storeFunc(args[0]);
        return response;
      } catch (error) {
        setIsError(true);
        return [];
      } finally {
        setIsLoading(false);
      }
    };

    return {
      error: isError,
      loading: isLoading,
      getVendorAvailableTimeslots,
      clearError: () => setIsError(false),
    };
  };
