import { HttpService } from "services";
import { Photo } from "types/entities";
import { GetPhotosFunction } from "types/functions";

import { AuthorizationLevel } from "types/http";
import { generateQueryString } from "utils";

/**
 * Retrieve photos related to a given listing
 *
 * @throws {PhotoServiceException}
 */
export const getPhotos: GetPhotosFunction = async (
  ...args: Parameters<GetPhotosFunction>
) => {
  try {
    const { listingId, signal } = args[0];
    const response: Photo[] = await HttpService.fetch(
      `/photo${generateQueryString({ listingId })}`,
      {
        requestConfig: {
          method: "GET",
          signal: signal,
        },
      },
      AuthorizationLevel.AUTHORIZED
    );

    return response;
  } catch (error) {
    throw error;
  }
};
