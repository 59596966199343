import dayjs from "dayjs";

/**
 * Change number to time format
 *
 * eg. 120 => 02:00 am
 *
 */
export const numberToTimeString = (number: number): string => {
  const MINUTES_IN_DAY = 60 * 24;
  let n = number;

  if (n > MINUTES_IN_DAY) n -= Math.floor(n / MINUTES_IN_DAY) * MINUTES_IN_DAY;

  const militaryTimeHours = Math.floor(n / 60);
  const minutes = n % 60;

  return dayjs()
    .set("hour", militaryTimeHours)
    .set("minute", minutes)
    .format("hh:mm a");
};
