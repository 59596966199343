import { HttpService } from "services";
import { SendbirdToken } from "types/entities";

import { GetSendbirdSupportTokenFunction } from "types/functions";
import { AuthorizationLevel } from "types/http";

/**
 * Retrieve sendbird session token of the support user
 *
 * @throws {HttpServiceException}
 */
export const getSendbirdSupportToken: GetSendbirdSupportTokenFunction =
  async () => {
    try {
      let url = `/chat/support-session-token`;

      const res: SendbirdToken = await HttpService.fetch(
        url,
        {
          requestConfig: {
            method: "GET",
          },
        },
        AuthorizationLevel.AUTHORIZED
      );

      return res;
    } catch (error) {
      throw error;
    }
  };
