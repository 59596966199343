export function generateQueryString(dict: Record<string, string | undefined>) {
  const cleanDict: { [key: string]: string } = {};
  Object.keys(dict).forEach((key) => {
    if (dict[key] !== undefined && dict[key] !== '') {
      cleanDict[key] = dict[key] as string;
    }
  });
  const params = new URLSearchParams(cleanDict);
  const paramsString = params.toString();
  if (paramsString) return `?${paramsString}`;
  return '';
}
