import { HttpService } from "services";
import { GeneratedDocumentPayload } from "types/entities";

import {
  GenerateDocumentPayloadFunction,
  GenerateDocumentPayloadParams,
} from "types/functions";
import { AuthorizationLevel } from "types/http";
import { generateQueryString } from "utils";

/**
 * Retrieve payload for a specified eversign document type
 *
 * @throws {HttpServiceException}
 */
export const generateDocumentPayload: GenerateDocumentPayloadFunction = async (
  params: GenerateDocumentPayloadParams
) => {
  try {
    const { listingId, offerId, eversignDocumentType } = params;
    const url = `/document/${eversignDocumentType}/generate-payload${generateQueryString({ listingId, offerId })}`;

    const res: GeneratedDocumentPayload = await HttpService.fetch(
      url,
      {
        requestConfig: {
          method: "GET",
        },
      },
      AuthorizationLevel.AUTHORIZED
    );

    return res;
  } catch (error) {
    throw error;
  }
};
