import { useState } from "react";

import { useWalkthroughUrlStore } from "store";
import { SetWalkthroughUrlFunction } from "types/functions/setWalkthroughUrl";
import { BaseAsyncHook } from "types/hooks";

interface UseSetWalkthroughUrl extends BaseAsyncHook {
  setWalkthroughUrl: SetWalkthroughUrlFunction;
}

export const useSetWalkthroughUrl = (): UseSetWalkthroughUrl => {
  const _setWalkthroughUrl = useWalkthroughUrlStore((state) => state.setWalkthroughUrl);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const setWalkthroughUrl = async (...args: Parameters<SetWalkthroughUrlFunction>) => {
    try {
      setIsLoading(true);
      setIsError(false);
      const response = await _setWalkthroughUrl(args[0]);
      return response;
    } catch (error) {
      setIsError(true);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error: isError,
    loading: isLoading,
    setWalkthroughUrl,
  };
};
