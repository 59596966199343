import { HttpService } from "services";
import { EndpointTitleDocument } from "types/entities";
import {
  GetTitleDocumentsFunction,
  GetTitleDocumentsFunctionParams,
} from "types/functions";
import { AuthorizationLevel } from "types/http";
import { generateQueryString } from "utils";

/**
 * Retrieve title documents
 *
 * @throws {HttpServiceException}
 */
export const getTitleDocuments: GetTitleDocumentsFunction =
  async (params: GetTitleDocumentsFunctionParams) => {
    try {
      const { listingId, transactionId } = params;
      let url = `/title/${transactionId}/documents${generateQueryString({ listingId })}`;

      const res: EndpointTitleDocument[] = await HttpService.fetch(
        url,
        {
          requestConfig: {
            method: "GET",
          },
        },
        AuthorizationLevel.AUTHORIZED
      );

      return res;
    } catch (error) {
      throw error;
    }
  };
