import { HttpService } from "services";

import {
  SetBulkFinalDocumentHashFunction,
  SetBulkFinalDocumentHashParams,
} from "types/functions";
import { AuthorizationLevel } from "types/http";
import { generateQueryString } from "utils";

/**
 * ...
 *
 * @throws {HttpServiceException}
 */
export const setBulkFinalDocumentHash: SetBulkFinalDocumentHashFunction =
  async (params: SetBulkFinalDocumentHashParams) => {
    try {
      const { eversignDocumentType, eversignDocumentHash, groupId } = params;
      const url = `/document/set-final-document-hash/bulk-group/${groupId}${generateQueryString(
        {
          eversignDocumentHash,
          eversignDocumentType,
        }
      )}`;

      await HttpService.fetch(
        url,
        {
          requestConfig: {
            method: "POST",
          },
        },
        AuthorizationLevel.AUTHORIZED
      );

      return true;
    } catch (error) {
      throw error;
    }
  };
