import { useState } from "react";

import { useListingsStore } from "store";
import { UpdateListingFunction, } from "types/functions";
import { BaseAsyncHook } from "types/hooks";

interface UseUpdateListing extends BaseAsyncHook {
  updateListing: UpdateListingFunction;
}

export const useUpdateListing = (): UseUpdateListing => {
  const _updateListing = useListingsStore((state) => state.updateListing);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const updateListing = async (...args: Parameters<UpdateListingFunction>) => {
    try {
      setIsLoading(true);
      setIsError(false);
      const listing = await _updateListing(args[0]);
      return listing;
    } catch (error) {
      setIsError(true);
      return {};
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error: isError,
    loading: isLoading,
    updateListing,
  };
};
