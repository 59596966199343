import { DetailedListing } from "types/entities";

export const formatListingAddress = (listing: DetailedListing) => {
  const { address_line_1, address_line_2, city, state, zip } = listing;
  let address = '';
  if (listing?.address_line_1) address += address_line_1 + ', ';
  if (listing?.address_line_2) address += address_line_2 + ', ';
  if (listing?.city) address += city + ', ';
  if (listing?.state) address += state + ' ';
  if (listing?.zip) address += zip + ' ';

  return address;
}