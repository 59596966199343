import { AuthorizationLevel } from "types/http";
import { HttpService } from "services";
import { GetVendorsWithZipcodesFunction } from "types/functions";
import { ApiUser } from "types/api";

/**
 * Retrieve vendor users along with their zip code assignments.
 *
 * @throws {UserServiceException}
 */
export const getVendorsWithZipcodes: GetVendorsWithZipcodesFunction = async (
  ...args: Parameters<GetVendorsWithZipcodesFunction>
) => {
  try {
    const response = await HttpService.fetch<ApiUser[]>(
      '/vendor-zipcode',
      {
        requestConfig: {
          method: "GET",
        },
      },
      AuthorizationLevel.AUTHORIZED
    );

    return response.map((user) => HttpService.Serializer.serializeUser(user));
  } catch (error) {
    throw error;
  }
};
