import { HttpService } from "services";
import { SetUserPasswordFunction } from "types/functions";
import { AuthorizationLevel } from "types/http";

/**
 * Set password.
 *
 * @throws {UserServiceException}
 */
export const setUserPassword: SetUserPasswordFunction = async (
  ...args: Parameters<SetUserPasswordFunction>
) => {
  try {
    const { email, password, oldPassword } = args[0];
    await HttpService.fetch(
      "/user/reset-password",
      {
        requestConfig: {
          method: "POST",
          data: {
            email,
            password,
            oldPassword,
          },
        },
      },
      AuthorizationLevel.UNAUTHORIZED
    );

    return true;
  } catch (error) {
    throw error;
  }
};
