import { Box } from "@mui/material";
import { ErrorMessage, LoadingIndicator, Table, Text } from "components/common";
import { useGenerateTableData, useGetTitles } from "hooks";
import { useEffect } from "react";
import { useTitleStore } from "store";
import { TableDataItemValue } from "types/components";
import { DetailedListing, EndpointTitle, Listing } from "types/entities";

interface OwnProps {
  listing: Listing | DetailedListing;
  onRowClick: (item: Record<string, TableDataItemValue>) => void;
  selectedTitle: EndpointTitle | null;
}

export const TitlesSelectionTable: React.FC<OwnProps> = ({
  listing,
  onRowClick,
  selectedTitle,
}) => {
  const { getTitles, loading, error } = useGetTitles();
  const { titles } = useTitleStore();

  const titlesTableData = useGenerateTableData({
    columnNames: [
      "Transaction Id",
      "External Order Id",
      "Street 1",
      "Street 2",
      "City",
      "State",
      "Zip",
      "County",
    ],
    data: titles
      ? titles.map((title) => ({
          hidden: [
            {
              name: "_id",
              value: title.transactionId,
            },
          ],
          visible: [
            title.transactionId,
            title.externalOrderId,
            title.property.address.street1,
            title.property.address.street2,
            title.property.address.city,
            title.property.address.state,
            title.property.address.zip,
            title.property.address.county,
          ],
        }))
      : [],
    onRowClick,
  });

  useEffect(() => {
    getTitles({ listingId: listing.id });
  }, [listing]);

  return (
    <Box>
      <Text variant="h5" fontWeight={600}>
        Select a title
      </Text>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <Table
          tableData={titlesTableData}
          dataIdKey="_id"
          onRowClick={titlesTableData.onRowClick}
          selectedRowId={selectedTitle?.transactionId}
        />
      )}
      {error && <ErrorMessage />}
    </Box>
  );
};
