import { HttpService } from "services";
import { ApiUser } from "types/api";
import { User } from "types/entities";
import { AuthorizationLevel } from "types/http";

/**
 * Retrieve currently logged in user.
 *
 * @throws {UserServiceException}
 */
export const getMe = async (): Promise<User> => {
  try {
    const response = await HttpService.fetch<ApiUser>(
      "/user/me",
      {
        requestConfig: {
          method: "GET",
        },
      },
      AuthorizationLevel.AUTHORIZED
    );

    return HttpService.Serializer.serializeUser(response);
  } catch (error) {
    throw error;
  }
};
