import { HttpService } from "services";
import { GenerateResetCodeFunction } from "types/functions";
import { AuthorizationLevel } from "types/http";

/**
 *
 *
 * @throws {UserServiceException}
 */
export const generateResetCode: GenerateResetCodeFunction = async (
  ...args: Parameters<GenerateResetCodeFunction>
): Promise<void> => {
  try {
    const [email] = args;
    await HttpService.fetch(
      "/auth/generate-reset-code",
      {
        requestConfig: {
          method: "POST",
          data: {
            email,
          },
        },
      },
      AuthorizationLevel.UNAUTHORIZED
    );
  } catch (error) {
    throw error;
  }
};
