import { AuthorizationLevel } from "types/http";
import { Event } from "types/entities";
import { HttpService } from "services";
import { AssignVendorToEventFunction } from "types/functions";
import { generateQueryString } from "utils";

/**
 * Assign Vendor to Event.
 *
 * @throws {UserServiceException}
 */
export const assignToEvent: AssignVendorToEventFunction = async (
  ...args: Parameters<AssignVendorToEventFunction>
) => {
  const [parameters] = args;
  const { eventId, vendorId } = parameters;
  try {
    const response = await HttpService.fetch<Event>(
      `/event/${eventId}/assign-vendor-to-event${generateQueryString({ vendorId })}`,
      {
        requestConfig: {
          method: "POST",
        },
      },
      AuthorizationLevel.AUTHORIZED
    );

    return response;
  } catch (error) {
    throw error;
  }
};
