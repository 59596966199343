import { HttpService } from "services";

import { UploadPhotosFunction } from "types/functions";
import { AuthorizationLevel } from "types/http";
import { generateQueryString } from "utils";

/**
 * Upload photos related to a given listing
 *
 * @throws {HttpServiceException}
 */
export const uploadPhotos: UploadPhotosFunction = async (
  ...args: Parameters<UploadPhotosFunction>
) => {
  try {
    const { listingId, files } = args[0];
    const url = `/photo${generateQueryString({ listingId: listingId || undefined })}`
    
    const formData = new FormData();
    if (files) {
      for (let i = 0; i < files.length; i++) {
        formData.append('photos', files[i])
      }
    }
    await HttpService.fetch(
      url,
      {
        requestConfig: {
          method: "POST",
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        },
      },
      AuthorizationLevel.AUTHORIZED
    );

    return true;
  } catch (error) {
    throw error;
  }
};
