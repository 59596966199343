import { useState } from "react";

import { useListingsStore } from "store";
import { RemoveListingFunction, } from "types/functions";
import { BaseAsyncHook } from "types/hooks";

interface UseRemoveListing extends BaseAsyncHook {
  removeListing: RemoveListingFunction;
}

export const useRemoveListing = (): UseRemoveListing => {
  const _storeFunc = useListingsStore((state) => state.removeListing);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const removeListing = async (...args: Parameters<RemoveListingFunction>) => {
    try {
      setIsLoading(true);
      setIsError(false);
      const response = await _storeFunc(args[0]);
      return response;
    } catch (error) {
      setIsError(true);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error: isError,
    loading: isLoading,
    removeListing,
  };
};
