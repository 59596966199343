import { Box, Stack } from "@mui/material";
import { PaperSurface, Text } from "components/common";
import React from "react";

interface OwnProps {
  children: JSX.Element | JSX.Element[] | string;
  title?: string;
}

export const ViewWrapper: React.FC<OwnProps> = ({ children, title }) => {
  return (
    <Box maxWidth="95vw">
      <Stack>
        <PaperSurface
          sx={{ padding: "10px 30px 30px" }}
          style={{ maxWidth: "100%" }}
        >
          {title && (
            <Text variant="h4" fontWeight={600} marginBottom="20px">
              {title}
            </Text>
          )}
          {children}
        </PaperSurface>
      </Stack>
    </Box>
  );
};
