import create from "zustand";

import {
  GetPhotosFunction,
} from "types/functions";
import { Photo } from "types/entities";
import { PhotoService } from "services";
import { SetPhotosFunction } from "types/functions/setPhotos";
import { RemovePhotoFunction } from "types/functions/removePhoto";

interface PhotoStore {
  photos: Photo[];
  getPhotos: GetPhotosFunction;
  setPhotos: SetPhotosFunction;
  removePhoto: RemovePhotoFunction;
}

export const usePhotoStore = create<PhotoStore>((set) => ({
  photos: [],
  getPhotos: async (...args: Parameters<GetPhotosFunction>) => {
    try {
      const photos = await PhotoService.getPhotos(...args);
      set({ photos });
      return photos;
    } catch (error) {
      throw error;
    }
  },
  setPhotos: async (...args: Parameters<SetPhotosFunction>) => {
    try {
      const response = await PhotoService.setPhotos(...args);
      return response;
    } catch (error) {
      throw error;
    }
  },
  removePhoto: async (...args: Parameters<RemovePhotoFunction>) => {
    try {
      const response = await PhotoService.removePhoto(...args);
      return response;
    } catch (error) {
      throw error;
    }
  },
}));
