import { create } from "zustand";

import { GeneratedDocumentPayload, ListingDocument } from "types/entities";
import { DocumentService } from "services";
import {
  GenerateDocumentPayloadFunction,
  DownloadDocumentsFunction,
  RemoveDocsFunction,
  SetFinalDocumentHashFunction,
  SubmitInspectionDocsFunction,
  SetBulkFinalDocumentHashFunction,
} from "types/functions";
import { GetDocumentsFunction } from "types/functions/getDocuments";

interface DocumentStore {
  documents: ListingDocument[];
  documentsForDownload: ListingDocument[];
  generatedDocumentPayload: GeneratedDocumentPayload | undefined;
  clearGeneratedDocumentPayload: () => void;
  downloadDocuments: DownloadDocumentsFunction;
  getDocuments: GetDocumentsFunction;
  submitInspectionDocs: SubmitInspectionDocsFunction;
  removeDocs: RemoveDocsFunction;
  generateDocumentPayload: GenerateDocumentPayloadFunction;
  setFinalDocumentHash: SetFinalDocumentHashFunction;
  setBulkFinalDocumentHash: SetBulkFinalDocumentHashFunction;
}

export const useDocumentStore = create<DocumentStore>((set) => ({
  documents: [],
  documentsForDownload: [],
  generatedDocumentPayload: undefined,
  getDocuments: async (...args: Parameters<GetDocumentsFunction>) => {
    try {
      const documents = await DocumentService.getDocuments(...args);
      set({ documents });

      return documents;
    } catch (error) {
      throw error;
    }
  },
  downloadDocuments: async (...args: Parameters<DownloadDocumentsFunction>) => {
    try {
      const documents = await DocumentService.downloadDocuments(...args);
      set({ documentsForDownload: documents });

      return documents;
    } catch (error) {
      throw error;
    }
  },
  submitInspectionDocs: async (
    ...args: Parameters<SubmitInspectionDocsFunction>
  ) => {
    try {
      const response = await DocumentService.submitInspectionDocs(...args);

      return response;
    } catch (error) {
      throw error;
    }
  },
  removeDocs: async (...args: Parameters<RemoveDocsFunction>) => {
    try {
      const response = await DocumentService.removeDocs(...args);

      return response;
    } catch (error) {
      throw error;
    }
  },
  generateDocumentPayload: async (
    ...args: Parameters<GenerateDocumentPayloadFunction>
  ) => {
    try {
      const generatedDocumentPayload =
        await DocumentService.generateDocumentPayload(...args);
      set({ generatedDocumentPayload });
      return generatedDocumentPayload;
    } catch (error) {
      throw error;
    }
  },
  clearGeneratedDocumentPayload: () => {
    set({ generatedDocumentPayload: undefined });
  },
  setFinalDocumentHash: async (
    ...args: Parameters<SetFinalDocumentHashFunction>
  ) => {
    try {
      const response = await DocumentService.setFinalDocumentHash(...args);
      return response;
    } catch (error) {
      throw error;
    }
  },
  setBulkFinalDocumentHash: async (
    ...args: Parameters<SetBulkFinalDocumentHashFunction>
  ) => {
    try {
      const response = await DocumentService.setBulkFinalDocumentHash(...args);
      return response;
    } catch (error) {
      throw error;
    }
  },
}));
