import { useState } from "react";

import { useUserStore } from "store";
import { SignInFunction } from "types/functions";
import { BaseAsyncHook } from "types/hooks";
import { UserInStore } from "types/store";
import { ChangeFunctionReturnType } from "types/utils";

interface UseSignIn extends BaseAsyncHook {
  user: UserInStore;
  loading: boolean;
  error: boolean;
  signIn: ChangeFunctionReturnType<SignInFunction, Promise<void>>;
}

export const useSignIn = (): UseSignIn => {
  const storeUser = useUserStore((state) => state.authorizedUser);
  const _signIn = useUserStore((state) => state.signIn);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const signIn = async (...args: Parameters<SignInFunction>) => {
    try {
      setIsLoading(true);
      await _signIn(...args);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error: isError,
    loading: isLoading,
    user: storeUser,
    signIn,
  };
};
