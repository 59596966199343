import { AuthorizationLevel } from "types/http";
import { HttpService } from "services";
import { GetVendorsWithTimeslotsFunction } from "types/functions";
import { ApiTimeslot, ApiUser } from "types/api";
import { generateQueryString } from "utils";

/**
 * Retrieve vendor users along with their available timeslots for a given date.
 *
 * @throws {UserServiceException}
 */
export const getVendorsWithTimeslots: GetVendorsWithTimeslotsFunction = async (
  ...args: Parameters<GetVendorsWithTimeslotsFunction>
) => {
  try {
    const [params] = args;
    const { date, role } = params;
    const response = await HttpService.fetch<
      {
        vendor: ApiUser;
        timeslots: ApiTimeslot[];
      }[]
    >(
      `/user/vendors/timeslots${generateQueryString({ vendorRole: role, dateSpecific: date.toISOString() })}`,
      {
        requestConfig: {
          method: "GET",
        },
      },
      AuthorizationLevel.AUTHORIZED
    );

    return response.map((stream) => {
      return {
        timeslots: stream.timeslots.map(
          HttpService.Serializer.serializeTimeslot
        ),
        vendor: HttpService.Serializer.serializeUser(stream.vendor),
      };
    });
  } catch (error) {
    throw error;
  }
};
