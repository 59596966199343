import { useEffect, useMemo, useState } from "react";

import { useUserStore } from "store";
import { GetVendorsFunction } from "types/functions";
import { BaseAsyncHook, BaseAsyncHookInput } from "types/hooks";
import { UsersInStore } from "types/store";
import { parseAsyncHookConfiguration } from "utils";

interface UseGetVendors extends BaseAsyncHook {
  vendors: UsersInStore;
}

export const useGetVendors = (props?: BaseAsyncHookInput): UseGetVendors => {
  const config = parseAsyncHookConfiguration(props?.configuration);

  const _getVendors = useUserStore((state) => state.getVendors);

  const _usersInStore = useUserStore<UsersInStore>((state) => state.users);

  const _vendors = useMemo(() => {
    if (!_usersInStore) return undefined;

    return _usersInStore.filter((user) => user.isVendor === true);
  }, [_usersInStore]);

  const [isLoading, setIsLoading] = useState<boolean>(
    config.preventNetworkRequest ? false : true
  );
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    const onLoad = async (...args: Parameters<GetVendorsFunction>) => {
      try {
        setIsError(false);
        setIsLoading(true);
        await _getVendors(...args);
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    if (config.preventNetworkRequest === false) onLoad();
  }, [_getVendors, config.preventNetworkRequest]);

  return {
    vendors: _vendors,
    error: isError,
    loading: isLoading,
  };
};
