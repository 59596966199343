import { HttpService } from "services";
import { DetailedListing } from "types/entities";

import { UpdateListingFunction, UpdateListingFunctionParams } from "types/functions";
import { AuthorizationLevel } from "types/http";
import { generateQueryString } from "utils";

/**
 * Retrieve detailed information about listing
 * 
 * @throws {HttpServiceException}
 */
export const updateListing: UpdateListingFunction = async (
  params: UpdateListingFunctionParams
) => {
  try {
    const { listing } = params;
    
    // When retrieving listing information, the payload adds certain flags to the listing
    // which, in fact exist as parts of the 'features' table. We need to get
    // rid of them before sending data to the backend
    const {
      has_ac,
      has_pool,
      ...filteredListing
    } = listing;
    const url = `/listing/${listing.id}${generateQueryString({ skipCamelToSnake: 'true' })}`;
    
    const res: DetailedListing = await HttpService.fetch(
      url,
      {
        requestConfig: {
          method: "PATCH",
          data: filteredListing,
        },
      },
      AuthorizationLevel.AUTHORIZED
    );

    return res;
  } catch (error) {
    throw error;
  }
};
