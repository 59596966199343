import dayjs from "dayjs";
import { DateString } from "types/common";
import { DateFormatter } from "utils";

/**
 * Format date object to fixed datetime string.
 */
export const toDateTimeString = (date: Date | string): DateString | 'Invalid Date' => {
  let _date = date;
  if (typeof _date === "string") _date = dayjs(_date).toDate();

  return DateFormatter.format(date, "MMMM DD, YYYY, HH:MM") as DateString;
};
