import { UserRole } from "types/entities";

/**
 * Declaration of url paths available in this application.
 *
 * Should be consumed by a routing library.
 */
export {};

/**
 * All routes available in the app
 */
export enum AUTH_ROUTER_PATHS {
  APP_SETTINGS = "/app-settings",
  DASHBOARD = "/*",
  MANAGE_LISTINGS = "/manage-listings",
  MANAGE_FILES = "/manage-files",
  BULK_ADD_LISTINGS = "/bulk-add-listings",
  MANAGE_BULK_GROUPS = "/manage-bulk-groups",
  MANAGE_USERS = "/manage-users",
  VENDOR_ZIPCODES = "/vendor-zipcodes",
  VENDOR_EVENTS = "/vendor-events",
  MANAGE_TITLES = "/manage-titles",
  MANAGE_VIRTUAL_WALKTHROUGHS = "/manage-virtual-walkthroughs",
  CHANGE_PASSWORD = "/change-password",
  MESSAGES = "/messages",
  GENERATE_DOCUMENTS = "/generate-documents",
}

/**
 * AUTH_ROUTER_PATHS to which specific UserRole(s) have access to
 */
export const AUTH_ROUTER_ROLE_PATHS = {
  // Administrator can access every view
  [UserRole.ADMINISTRATOR]: Object.keys(AUTH_ROUTER_PATHS).map(
    (key) => AUTH_ROUTER_PATHS[key as keyof typeof AUTH_ROUTER_PATHS]
  ),
  [UserRole.VENDOR]: [
    AUTH_ROUTER_PATHS.DASHBOARD,
    AUTH_ROUTER_PATHS.MANAGE_FILES,
    AUTH_ROUTER_PATHS.MANAGE_VIRTUAL_WALKTHROUGHS,
    AUTH_ROUTER_PATHS.CHANGE_PASSWORD,
    AUTH_ROUTER_PATHS.MESSAGES,
  ],
  [UserRole.LISTING_SPECIALIST]: [
    AUTH_ROUTER_PATHS.DASHBOARD,
    AUTH_ROUTER_PATHS.MANAGE_LISTINGS,
    AUTH_ROUTER_PATHS.MANAGE_VIRTUAL_WALKTHROUGHS,
    AUTH_ROUTER_PATHS.MANAGE_FILES,
    AUTH_ROUTER_PATHS.VENDOR_ZIPCODES,
    AUTH_ROUTER_PATHS.CHANGE_PASSWORD,
    AUTH_ROUTER_PATHS.MANAGE_TITLES,
    AUTH_ROUTER_PATHS.MESSAGES,
    AUTH_ROUTER_PATHS.GENERATE_DOCUMENTS,
  ],
  [UserRole.CUSTOMER]: [],
};

export enum UNATH_ROUTER_PATHS {
  SIGN_IN = "/sign-in",
  RESET_PASSWORD = "/reset-password",
  CHANGE_PASSWORD = "/change-password",
}
