import { useState } from "react";

import { useUploadStore } from "store";
import { UploadDocsFunction, UploadPhotosFunction } from "types/functions";
import { BaseAsyncHook } from "types/hooks";

/*
  ManageFiles view needs to differentiate between loading states of
  uploading documents & uploading photos, hence specific loadingDocs, loadingPhotos
  are present
*/
interface UseUploadFiles extends BaseAsyncHook {
  loading: boolean;
  error: boolean;
  loadingDocs: boolean;
  errorDocs: boolean;
  loadingPhotos: boolean;
  errorPhotos: boolean;
  uploadDocs: UploadDocsFunction;
  uploadPhotos: UploadPhotosFunction;
}

export const useUploadFiles = (): UseUploadFiles => {
  const _uploadDocs = useUploadStore((state) => state.uploadDocs);
  const _uploadPhotos = useUploadStore((state) => state.uploadPhotos);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoadingDocs, setIsLoadingDocs] = useState<boolean>(false);
  const [isErrorDocs, setIsErrorDocs] = useState<boolean>(false);
  const [isLoadingPhotos, setIsLoadingPhotos] = useState<boolean>(false);
  const [isErrorPhotos, setIsErrorPhotos] = useState<boolean>(false);

  const uploadDocs = async (...args: Parameters<UploadDocsFunction>) => {
    try {
      setIsLoading(true);
      setIsLoadingDocs(true);
      setIsError(false);
      setIsErrorDocs(false);
      await _uploadDocs(args[0]);
      return true;
    } catch (error) {
      setIsError(true);
      setIsErrorDocs(true);
      return false;
    } finally {
      setIsLoading(false);
      setIsLoadingDocs(false);
    }
  };

  const uploadPhotos = async (...args: Parameters<UploadPhotosFunction>) => {
    try {
      setIsLoading(true);
      setIsLoadingPhotos(true);
      setIsError(false);
      setIsErrorPhotos(false);
      await _uploadPhotos(args[0]);
      return true;
    } catch (error) {
      setIsError(true);
      setIsErrorPhotos(true);
      return false;
    } finally {
      setIsLoading(false);
      setIsLoadingPhotos(false);
    }
  };

  return {
    error: isError,
    loading: isLoading,
    loadingDocs: isLoadingDocs,
    errorDocs: isErrorDocs,
    loadingPhotos: isLoadingPhotos,
    errorPhotos: isErrorPhotos,
    uploadDocs,
    uploadPhotos,
  };
};
