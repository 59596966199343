import { HttpService } from "services";
import {
  UploadTitleDocumentFunction,
  UploadTitleDocumentFunctionParams,
} from "types/functions";
import { AuthorizationLevel } from "types/http";
import { generateQueryString } from "utils";

/**
 * Upload title document
 *
 * @throws {HttpServiceException}
 */
export const uploadTitleDocument: UploadTitleDocumentFunction = async (
  params: UploadTitleDocumentFunctionParams
) => {
  try {
    const { listingId, transactionId } = params;
    let url = `/title${transactionId}/documents${generateQueryString({ listingId })}`;

    const res: boolean = await HttpService.fetch(
      url,
      {
        requestConfig: {
          method: "POST",
        },
      },
      AuthorizationLevel.AUTHORIZED
    );

    return res;
  } catch (error) {
    throw error;
  }
};
