import { HttpService } from "services";
import { ListingSeller } from "types/entities";

import { GetListingSellerFunction, GetListingSellerFunctionParams } from "types/functions";
import { AuthorizationLevel } from "types/http";

/**
 * Retrieve detailed information about listing
 * 
 * @throws {HttpServiceException}
 */
export const getListingSeller: GetListingSellerFunction = async (
  params: GetListingSellerFunctionParams
) => {
  try {
    const { listing } = params;
    let url = `/user/${listing.seller_id}`;
    
    const res: ListingSeller = await HttpService.fetch(
      url,
      {
        requestConfig: {
          method: "GET",
        },
      },
      AuthorizationLevel.AUTHORIZED
    );

    return res;
  } catch (error) {
    throw error;
  }
};
