import { Button, Grid, Link, Stack, TextField } from "@mui/material";
import { notification } from "antd";
import {
  ErrorMessage,
  LoadingIndicator,
  Select,
  Table,
  Text,
} from "components/common";
import { ViewWrapper } from "components/wrappers";
import dayjs from "dayjs";
import {
  useBulkApproveListings,
  useBulkSetFinalDocumentHash,
  useGetBulkGroup,
  useGetBulkGroups,
  useGetGeneratedDocumentPayload,
} from "hooks";
import React, { useEffect, useMemo, useState } from "react";
import { useDocumentStore } from "store";
import { EntityId } from "types/common";
import { TableData, TableDataItem } from "types/components";
import { BulkUploadGroup, EversignDocumentType, Listing } from "types/entities";
import { INITIAL_LISTINGS_TABLE_DATA } from "views/ManageBulkGroups/constants";

interface ManageBulkGroups {}

export const ManageBulkGroups: React.FC<ManageBulkGroups> = () => {
  const { getBulkGroups, error: getBulkGroupsError } = useGetBulkGroups();
  const { getBulkGroup, error: getBulkGroupError } = useGetBulkGroup();
  const {
    bulkApproveListings,
    error: bulkApproveListingsError,
    loading: bulkApproveListingsLoading,
  } = useBulkApproveListings();
  const [bulkGroups, setBulkGroups] = useState<BulkUploadGroup[] | null>(null);
  const [getBulkGroupsLoading, setGetBulkGroupsLoading] =
    useState<boolean>(true);
  const [selectedBulkGroup, setSelectedBulkGroup] = useState<EntityId | null>(
    null
  );
  const [groupListings, setGroupListings] = useState<null | Listing[]>(null);
  const [listingsTableData, setListingsTableData] = useState<TableData>({
    ...INITIAL_LISTINGS_TABLE_DATA,
  });
  const {
    setBulkFinalDocumentHash,
    loading: loadingHash,
    error: errorHash,
  } = useBulkSetFinalDocumentHash();
  const { getGeneratedDocumentPayload } = useGetGeneratedDocumentPayload();
  const { generatedDocumentPayload, clearGeneratedDocumentPayload } =
    useDocumentStore();
  // Allow only listing agreements for now
  const [selectedEversignDocumentType] = useState<EversignDocumentType>(
    EversignDocumentType.LISTING_AGREEMENT
  );
  const [newDocumentLink, setNewDocumentLink] = useState<string | null>(null);
  const [newDocumentLinkIsInvalid, setNewDocumentLinkIsInvalid] =
    useState<boolean>(false);
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    (async () => {
      setBulkGroups(await getBulkGroups());
      setGetBulkGroupsLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (getBulkGroupsError) {
      api.error({
        message:
          "There was an error while getting bulk groups. Please try again.",
        placement: "bottomRight",
      });
    }
  }, [getBulkGroupsError]);

  useEffect(() => {
    if (getBulkGroupsError) {
      api.error({
        message:
          "There was an error while getting selected bulk group. Please try again.",
        placement: "bottomRight",
      });
    }
  }, [getBulkGroupError]);

  useEffect(() => {
    if (bulkApproveListingsError) {
      api.error({
        message:
          "There was an error while approving listings. Please try again.",
        placement: "bottomRight",
      });
    }
  }, [bulkApproveListingsError]);

  useEffect(() => {
    (async () => {
      if (selectedBulkGroup === null || selectedBulkGroup.length === 0) return;

      setGroupListings(await getBulkGroup({ groupId: selectedBulkGroup }));
    })();
  }, [selectedBulkGroup]);

  useEffect(() => {
    mapTranslatedDataToTableData(
      groupListings ?? [],
      INITIAL_LISTINGS_TABLE_DATA,
      setListingsTableData
    );
    clearGeneratedDocumentPayload();

    if (groupListings === null || groupListings.length === 0) return;

    (async () => {
      await getGeneratedDocumentPayload({
        listingId: groupListings[0].id,
        eversignDocumentType: selectedEversignDocumentType,
      });
    })();
  }, [groupListings]);

  const mapTranslatedDataToTableData = (
    data: Listing[],
    initialTableData: TableData,
    setStateFunc: React.Dispatch<React.SetStateAction<TableData>>
  ) => {
    const newTableData: TableData = { ...initialTableData, data: [] };

    data.forEach((item) => {
      const tableItem: TableDataItem = {};
      // Make sure every tableItem contains 'id' field
      tableItem.id = item.id;

      newTableData.headers.forEach((header) => {
        const key = header.key as keyof Listing;
        // @ts-ignore
        tableItem[key] = item[key] as TableDataItemValue;
      });
      newTableData.data.push(tableItem);
    });
    setStateFunc({ ...newTableData });
  };

  const setStateOfGroupWithId = (
    selectedBulkGroup: string | null,
    partialUpdate: Partial<BulkUploadGroup>
  ): void => {
    setBulkGroups((groups) =>
      groups
        ? groups.map((group) =>
            group.id === selectedBulkGroup
              ? { ...group, ...partialUpdate }
              : group
          )
        : null
    );
  };

  const handleSubmitDocumentLink = async () => {
    if (!newDocumentLink || !selectedBulkGroup) return;
    const splitLink = newDocumentLink.split("/");
    let documentHash;
    try {
      documentHash = splitLink[splitLink.length - 1];
      if (!documentHash || documentHash.length !== 32) {
        throw Error;
      }
    } catch {
      setNewDocumentLinkIsInvalid(true);
      return;
    }
    await setBulkFinalDocumentHash({
      eversignDocumentType: selectedEversignDocumentType,
      eversignDocumentHash: documentHash,
      groupId: selectedBulkGroup,
    });
    setStateOfGroupWithId(selectedBulkGroup, {
      last_uploaded_document_url: documentHash,
      last_uploaded_document_timestamp: new Date(),
    });
    api.success({
      message: `Successfully set document`,
      placement: "bottomRight",
    });
    setNewDocumentLinkIsInvalid(false);
  };

  const lastDocumentSet: string | null = useMemo(() => {
    const hash = bulkGroups
      ? bulkGroups.find((group) => group.id === selectedBulkGroup)
          ?.last_uploaded_document_url
      : null;
    if (!hash) return null;
    return `https://realtybase.eversign.com/documents/${hash}`;
  }, [selectedBulkGroup, bulkGroups]);

  const lastDocumentSetTimestamp: Date | null = useMemo(() => {
    const timestamp = bulkGroups
      ? bulkGroups.find((group) => group.id === selectedBulkGroup)
          ?.last_uploaded_document_timestamp
      : null;
    return timestamp ?? null;
  }, [selectedBulkGroup, bulkGroups]);

  const handleGroupApprove = async (groupId: string | null) => {
    if (!groupId) return;
    await bulkApproveListings({
      groupId,
    });
    api.success({
      message: "Listings approved successfully",
      placement: "bottomRight",
    });
    setGroupListings((currentListings) =>
      currentListings
        ? currentListings.map((l) => ({ ...l, approved: true }))
        : null
    );
  };

  return (
    <ViewWrapper title="Manage Bulk Groups">
      {contextHolder}
      {getBulkGroupsLoading || getBulkGroups === null ? (
        <LoadingIndicator />
      ) : (
        <Stack spacing={2}>
          <Select
            onChange={setSelectedBulkGroup}
            options={bulkGroups!.map((group) => ({
              label: group.name,
              value: group.id,
            }))}
            value={selectedBulkGroup ?? ""}
            label="Select bulk group"
          />
          {selectedBulkGroup && groupListings && (
            <Table tableData={listingsTableData} />
          )}
          {selectedBulkGroup && groupListings && (
            <Grid
              container
              flexDirection={"row"}
              alignContent={"flex-start"}
              marginTop={3}
              xs={12}
            >
              {groupListings !== null && groupListings.length !== 0 && (
                <>
                  <Grid item flexDirection={"column"} xs={6}>
                    <Grid item direction={"column"} marginBottom={1}>
                      <Text variant="h5">Approve Listings</Text>
                      <Text variant="subtitle1">
                        Clicking button below will approve all Listings within
                        selected bulk group.
                      </Text>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        onClick={async () =>
                          await handleGroupApprove(selectedBulkGroup)
                        }
                        disabled={bulkApproveListingsLoading}
                      >
                        Approve Listings
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}

              <Grid container flexDirection={"column"} xs={6}>
                {groupListings !== null &&
                  groupListings.length !== 0 &&
                  generatedDocumentPayload !== undefined && (
                    <Grid item flexDirection={"column"}>
                      <Text variant="h5">
                        Generate Listing Agreement document for Listings
                      </Text>
                      <Text>
                        Please click the link below and populate the document
                        with values
                      </Text>
                      <Link
                        href={generatedDocumentPayload.link}
                        underline="always"
                        target="_blank"
                        marginY={1}
                      >
                        Click here to generate new document
                      </Link>
                      {lastDocumentSet && (
                        <Text>
                          This is the document set last time{" "}
                          {lastDocumentSetTimestamp && (
                            <b>
                              (
                              {`${dayjs(lastDocumentSetTimestamp).format(
                                "MM/DD/YYYY HH:mm:ss"
                              )}`}
                              )
                            </b>
                          )}
                          :{" "}
                          <Link
                            href={lastDocumentSet}
                            underline="hover"
                            target="_blank"
                          >
                            {lastDocumentSet}
                          </Link>
                        </Text>
                      )}
                      <Text marginY={1}>
                        Once you've created a document - copy & paste the link
                        to it below.
                      </Text>
                      <TextField
                        label="Document link"
                        value={newDocumentLink}
                        fullWidth
                        onChange={(e) => setNewDocumentLink(e.target.value)}
                      />
                      {newDocumentLinkIsInvalid && (
                        <>
                          <div style={{ marginTop: 10 }}></div>
                          <ErrorMessage
                            descriptionText={`Invalid document link - expected the link to be in following format: \n"https://realtybase.eversign.com/documents/XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"`}
                          />
                        </>
                      )}
                      <Button
                        variant="contained"
                        component="label"
                        disabled={!newDocumentLink || loadingHash}
                        style={{ marginTop: 10 }}
                        onClick={handleSubmitDocumentLink}
                      >
                        {loadingHash ? (
                          <LoadingIndicator indicatorColor="white" size={24} />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                      {errorHash && (
                        <ErrorMessage
                          descriptionText={
                            errorHash === true
                              ? `Failed to set new document hash`
                              : errorHash
                          }
                        />
                      )}
                    </Grid>
                  )}
              </Grid>
            </Grid>
          )}
        </Stack>
      )}
    </ViewWrapper>
  );
};
