import { ApiAppPrice } from "types/api";
import { PartialRecord } from "types/utils";

// We cannot assure that we'll be able to retrieve all types of prices from API.
export type AppPrices = PartialRecord<
  AppPriceType,
  Pick<ApiAppPrice, "id" | "price">
>;

export enum AppPriceType {
  INSPECTION = "inspection",
  PHOTO_SHOOT = "photo_shoot",
  REPORTS = "reports",
  LISTING_PAYMENT = "listing_payment",
}
