import { Button as MuiButton, ButtonProps } from "@mui/material";
import React from "react";

interface OwnProps extends ButtonProps {}

type TButton = OwnProps;

export const Button: React.FC<TButton> = (props) => {
  return <MuiButton {...props} />;
};
