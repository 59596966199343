import { HttpService } from "services";
import { ApiAppPrice } from "types/api";
import { GetAppPricesFunction } from "types/functions";
import { AuthorizationLevel } from "types/http";

/**
 *
 *
 * @throws {AppSettingsServiceException}
 */
export const getAppPrices: GetAppPricesFunction = async (
  ...args: Parameters<GetAppPricesFunction>
) => {
  try {
    const response = await HttpService.fetch<ApiAppPrice[]>(
      "/price",
      {
        requestConfig: {
          method: "GET",
        },
      },
      AuthorizationLevel.AUTHORIZED
    );

    return HttpService.Serializer.serializeAppPrices(response);
  } catch (error) {
    throw error;
  }
};
