import { TableData } from "types/components";
import { GetUsersFunctionParams } from "types/functions";

export const INITIAL_USERS_TABLE_DATA: TableData = {
  headers: [
    {
      title: 'Given Name',
      key: 'givenName',
    },
    {
      title: 'Family Name',
      key: 'familyName',
    },
    {
      title: 'Email',
      key: 'emailAddress',
    },
    {
      title: 'Address Line 1',
      key: 'addressLine1',
    },
    {
      title: 'Address Line 2',
      key: 'addressLine2',
    },
    {
      title: 'City',
      key: 'city',
    },
    {
      title: 'State',
      key: 'state',
    },
    {
      title: 'Zip',
      key: 'zip',
    },
    {
      title: 'Admin',
      key: 'isAdmin',
    },
    {
      title: 'Vendor',
      key: 'isVendor',
    },
    {
      title: 'Specialist',
      key: 'isListingSpecialist',
    },
  ],
  data: [],
}

export const INITIAL_GET_USERS_PARAMS: GetUsersFunctionParams = {
  isVendor: undefined,
  isListingSpecialist: undefined,
  isAdmin: undefined,
}

export enum UserFilter {
  ALL = 'all',
  SPECIALISTS = 'specialists',
  VENDORS = 'vendors',
  ADMINS = 'admins',
}