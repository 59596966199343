import { useState } from "react";

import { useUserStore } from "store";
import { SetUserPasswordFunction } from "types/functions";
import { BaseAsyncHook } from "types/hooks";

interface UseSetUserPassword extends BaseAsyncHook {
  setUserPassword: SetUserPasswordFunction;
}

export const useSetUserPassword = (): UseSetUserPassword => {
  const _storeFunc = useUserStore((state) => state.setUserPassword);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const setUserPassword = async (...args: Parameters<SetUserPasswordFunction>) => {
    try {
      setIsLoading(true);
      setIsError(false);
      const response = await _storeFunc(args[0]);
      return response;
    } catch (error) {
      setIsError(true);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error: isError,
    loading: isLoading,
    setUserPassword,
  };
};
