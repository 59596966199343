import { useState } from "react";

import { useListingsStore } from "store";
import {
  GetBulkGroupFunction,
  GetBulkGroupFunctionParams,
} from "types/functions";
import { BaseAsyncHook } from "types/hooks";

interface UseGetBulkGroup extends BaseAsyncHook {
  getBulkGroup: GetBulkGroupFunction;
}

export const useGetBulkGroup = (): UseGetBulkGroup => {
  const _storeFunc = useListingsStore((store) => store.getBulkGroup);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const getBulkGroup = async (params: GetBulkGroupFunctionParams) => {
    try {
      setIsLoading(true);
      setIsError(false);
      return await _storeFunc(params);
    } catch (error) {
      setIsError(true);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error: isError,
    loading: isLoading,
    getBulkGroup,
  };
};
