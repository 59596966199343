import { Grid, MenuItem, Stack, TextField } from "@mui/material";
import {
  Button,
  CardModal,
  ErrorMessage,
  LoadingIndicator,
  PDFViewer,
  Table,
  Text,
} from "components/common";
import { DIMENSIONS } from "consts";
import {
  useDownloadDocuments,
  useGetDocuments,
  useRemoveDocs,
  useSubmitInspectionDocs,
} from "hooks";
import { useEffect, useState } from "react";
import { useDocumentStore } from "store";
import { TableData, TableDataItem } from "types/components";
import {
  Listing,
  ListingDocument,
  ListingDocumentStatus,
  ListingDocumentType,
} from "types/entities";
import { SubmitInspectionDocsParams } from "types/functions";
import {
  INITIAL_LISTING_EVERSIGN_DOCS_TABLE_DATA,
  INITIAL_LISTING_INSPECTION_DOCS_TABLE_DATA,
} from "views/ManageListings";

// @TODO: add new section that lists only eversign documents

const INITIAL_MODAL_STATE = {
  isOpen: false,
};

interface OwnProps {
  selectedListing: string;
  reloadListing: () => void;
}

export const ManageListingsInspectionDocs: React.FC<OwnProps> = ({
  selectedListing,
  reloadListing,
}) => {
  const { submitInspectionDocs } = useSubmitInspectionDocs();
  const { downloadDocuments } = useDownloadDocuments();
  const { getDocuments } = useGetDocuments();
  const {
    removeDocs,
    loading: loadingRemoveDocs,
    error: errorRemoveDocs,
  } = useRemoveDocs();
  const { documentsForDownload, documents } = useDocumentStore();
  const [selectedInspectionDocs, setSelectedInspectionDocs] = useState<
    string[]
  >([]);
  const [listingInspectionDocsTableData, setListingInspectionDocsTableData] =
    useState<TableData>({ ...INITIAL_LISTING_INSPECTION_DOCS_TABLE_DATA });
  const [
    listingApprovedInspectionDocsTableData,
    setListingApprovedInspectionDocsTableData,
  ] = useState<TableData>({ ...INITIAL_LISTING_INSPECTION_DOCS_TABLE_DATA });
  const [listingEversignDocsTableData, setListingEversignDocsTableData] =
    useState<TableData>({ ...INITIAL_LISTING_EVERSIGN_DOCS_TABLE_DATA });
  const [selectedInspectionDocPreview, setSelectedInspectionDocPreview] =
    useState<string>("");
  const [
    selectedInspectionDocPreviewBase64,
    setSelectedInspectionDocPreviewBase64,
  ] = useState<string>("");
  const [modalState, setModalState] = useState({ ...INITIAL_MODAL_STATE });

  const handleRowClickMultiple = (
    item: TableDataItem,
    currState: string[],
    setStateFunc: React.Dispatch<React.SetStateAction<string[]>>
  ) => {
    let newState = [...currState.filter((elem) => elem !== item.id)];

    if (newState.length === currState.length) {
      // Length did not change, meaning an item was selected
      newState = [...newState, item.id as string];
    }
    setStateFunc([...newState]);
  };

  const mapAllInspectionDocsToTableData = (
    data: ListingDocument[],
    initialTableData: TableData,
    setStateFunc: React.Dispatch<React.SetStateAction<TableData>>
  ) => {
    const newTableData: TableData = { ...initialTableData, data: [] };

    data.forEach((item) => {
      const tableItem: TableDataItem = {};
      // Make sure every tableItem contains 'id' field
      tableItem.id = item.file_name;

      newTableData.headers.forEach((header) => {
        const key = header.key as keyof Listing;
        // @ts-ignore
        tableItem[key] = item[key] as TableDataItemValue;
      });
      newTableData.data.push(tableItem);
    });
    setStateFunc({ ...newTableData });
  };

  const mapApprovedInspectionDocsToTableData = (
    data: ListingDocument[],
    initialTableData: TableData,
    setStateFunc: React.Dispatch<React.SetStateAction<TableData>>
  ) => {
    const newTableData: TableData = { ...initialTableData, data: [] };

    data.forEach((item) => {
      if (item.status !== ListingDocumentStatus.APPROVED) return;

      const tableItem: TableDataItem = {};
      // Make sure every tableItem contains 'id' field
      tableItem.id = item.file_name;

      newTableData.headers.forEach((header) => {
        const key = header.key as keyof Listing;
        // @ts-ignore
        tableItem[key] = item[key] as TableDataItemValue;
      });
      newTableData.data.push(tableItem);
    });
    setStateFunc({ ...newTableData });
  };

  const mapEversignDocsToTableData = (
    data: ListingDocument[],
    initialTableData: TableData,
    setStateFunc: React.Dispatch<React.SetStateAction<TableData>>
  ) => {
    const newTableData: TableData = { ...initialTableData, data: [] };

    data.forEach((item) => {
      if (
        item.status !== ListingDocumentStatus.APPROVED ||
        item.eversign_document === null
      )
        return;

      const tableItem: TableDataItem = {};
      // Make sure every tableItem contains 'id' field
      tableItem.id = item.file_name;

      newTableData.headers.forEach((header) => {
        const key = header.key;
        switch (key) {
          case "type":
            // @ts-ignore
            tableItem[key] = item[key];
            break;
          case "link":
            // @ts-ignore
            tableItem[key] = `https://realtybase.eversign.com/documents/${item.eversign_document!.eversign_hash}`;
            break;

          default:
            break;
        }
      });
      newTableData.data.push(tableItem);
    });
    setStateFunc({ ...newTableData });
  };

  const getInspectionDocBase64Data = (fileName: string) => {
    const document = documentsForDownload.find(
      (document) => document.file_name === fileName
    );
    return document?.data || "";
  };

  const downloadDocument = () => {
    if (!selectedInspectionDocPreview) return;
    const linkSource = selectedInspectionDocPreviewBase64;
    const downloadLink = document.createElement("a");
    const fileName = selectedInspectionDocPreview;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const handleSubmitInspectionDocs = ({
    listingId,
    documents,
  }: SubmitInspectionDocsParams) => {
    submitInspectionDocs({
      listingId,
      documents,
    }).then(() => {
      downloadDocuments({ listingId, type: ListingDocumentType.INSPECTION });
      setSelectedInspectionDocs([]);
      reloadListing();
    });
  };

  const handleRemoveInspectionDocs = ({
    documents,
  }: {
    documents: string[];
  }) => {
    // documents are of the following format:
    // {documentId}.{listingId}.{file extension}
    const documentIds = documents.map((document) => {
      const [documentId, ...rest] = document.split(".");
      return documentId;
    });
    removeDocs({
      documentIds,
    }).then(() => {
      downloadDocuments({
        listingId: selectedListing,
        type: ListingDocumentType.INSPECTION,
      });
      setSelectedInspectionDocs([]);
      reloadListing();
    });
  };

  useEffect(() => {
    mapAllInspectionDocsToTableData(
      documentsForDownload,
      INITIAL_LISTING_INSPECTION_DOCS_TABLE_DATA,
      setListingInspectionDocsTableData
    );
    mapApprovedInspectionDocsToTableData(
      documentsForDownload,
      INITIAL_LISTING_INSPECTION_DOCS_TABLE_DATA,
      setListingApprovedInspectionDocsTableData
    );
  }, [documentsForDownload]);

  useEffect(() => {
    mapEversignDocsToTableData(
      documents,
      INITIAL_LISTING_EVERSIGN_DOCS_TABLE_DATA,
      setListingEversignDocsTableData
    );
  }, [documents]);

  console.log({ listingEversignDocsTableData });

  useEffect(() => {
    if (!selectedListing) return;
    else {
      downloadDocuments({
        listingId: selectedListing,
        type: ListingDocumentType.INSPECTION,
      });
      getDocuments({
        listingId: selectedListing,
      });
      setSelectedInspectionDocs([]);
    }
  }, [selectedListing]);

  useEffect(() => {
    setSelectedInspectionDocPreviewBase64(
      getInspectionDocBase64Data(selectedInspectionDocPreview)
    );
  }, [selectedInspectionDocPreview]);

  useEffect(() => {
    if (!errorRemoveDocs && !loadingRemoveDocs && modalState.isOpen) {
      setModalState({ ...INITIAL_MODAL_STATE });
    }
  }, [errorRemoveDocs, loadingRemoveDocs]);

  return (
    <>
      <Grid container alignItems="flex-start" marginTop="20px" spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Text variant="h5" fontWeight={600} marginBottom="20px">
                Select Inspection Documents
              </Text>
            </Grid>
            <Grid item xs={6}>
              <Text variant="h5" fontWeight={600} marginBottom="20px">
                Preview Document
              </Text>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              maxHeight={parseInt(DIMENSIONS.MAX_GRID_ITEM_HEIGHT) / 2}
              style={{ overflowY: "auto" }}
            >
              <Table
                tableData={listingInspectionDocsTableData}
                onRowClick={(item) =>
                  handleRowClickMultiple(
                    item,
                    selectedInspectionDocs,
                    setSelectedInspectionDocs
                  )
                }
                selectedRowIds={selectedInspectionDocs}
              />
            </Grid>
            <Grid item xs={12}>
              <Text variant="h5" fontWeight={600} marginBottom="20px">
                Approved Documents
              </Text>
            </Grid>
            <Grid
              item
              xs={12}
              maxHeight={parseInt(DIMENSIONS.MAX_GRID_ITEM_HEIGHT) / 2}
              style={{ overflowY: "auto" }}
            >
              <Table tableData={listingApprovedInspectionDocsTableData} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <TextField
                select
                fullWidth
                label="Select Document"
                value={selectedInspectionDocPreview}
                onChange={(e) =>
                  setSelectedInspectionDocPreview(e.target.value)
                }
              >
                {documentsForDownload.map((item) => (
                  <MenuItem key={item.file_name} value={item.file_name}>
                    {item.file_name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="contained"
                fullWidth
                onClick={downloadDocument}
                disabled={!selectedInspectionDocPreview}
              >
                Download File
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              maxHeight={DIMENSIONS.MAX_GRID_ITEM_HEIGHT}
              style={{ overflowY: "auto" }}
            >
              <PDFViewer base64={selectedInspectionDocPreviewBase64} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="error"
                fullWidth
                onClick={() =>
                  setModalState({
                    isOpen: true,
                  })
                }
                disabled={selectedInspectionDocs.length === 0}
              >
                Remove Inspection Documents
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="success"
                fullWidth
                onClick={() =>
                  handleSubmitInspectionDocs({
                    listingId: selectedListing,
                    documents: selectedInspectionDocs,
                  })
                }
                disabled={selectedInspectionDocs.length === 0}
              >
                Submit Inspection Documents
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() =>
                  handleSubmitInspectionDocs({
                    listingId: selectedListing,
                    documents: [],
                  })
                }
              >
                Clear Inspection Documents
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Text variant="h5" fontWeight={600} marginBottom="20px">
                Eversign Documents
              </Text>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            maxHeight={parseInt(DIMENSIONS.MAX_GRID_ITEM_HEIGHT) / 2}
            style={{ overflowY: "auto" }}
          >
            <Table tableData={listingEversignDocsTableData} />
          </Grid>
        </Grid>
      </Grid>
      <CardModal
        isOpen={modalState.isOpen}
        onClose={() => setModalState({ ...INITIAL_MODAL_STATE })}
      >
        <Stack spacing={2} direction="column">
          <Text variant="h6">
            Are you sure you want to remove these documents from the database?
          </Text>
          {selectedInspectionDocs.map((selectedInspectionDoc) => (
            <Text variant="subtitle1" key={selectedInspectionDoc}>
              {selectedInspectionDoc}
            </Text>
          ))}
          {errorRemoveDocs && <ErrorMessage />}
          <Button
            variant="contained"
            type="submit"
            disabled={loadingRemoveDocs}
            onClick={() =>
              handleRemoveInspectionDocs({ documents: selectedInspectionDocs })
            }
          >
            {loadingRemoveDocs ? (
              <LoadingIndicator size={24} indicatorColor="white" />
            ) : (
              "Confirm"
            )}
          </Button>
        </Stack>
      </CardModal>
    </>
  );
};
