import {  useState } from "react";

import { useDocumentStore } from "store";
import { GenerateDocumentPayloadFunction } from "types/functions";
import { BaseAsyncHook } from "types/hooks";

interface UseGetGeneratedDocumentPayload extends BaseAsyncHook {
  getGeneratedDocumentPayload: GenerateDocumentPayloadFunction;
}

export const useGetGeneratedDocumentPayload = (): UseGetGeneratedDocumentPayload => {
  const _storeFunc = useDocumentStore((state) => state.generateDocumentPayload);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const getGeneratedDocumentPayload = async (...args: Parameters<GenerateDocumentPayloadFunction>) => {
    try {
      setIsLoading(true);
      setIsError(false);
      const response = await _storeFunc(args[0]);
      return response;
    } catch (error) {
      setIsError(true);
      return {
        link: undefined,
        payload: {},
      };
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error: isError,
    loading: isLoading,
    getGeneratedDocumentPayload,
  };
};
