import { useState } from "react";
import { useUserStore } from "store";
import { BaseStringError } from "types/errors";

import {
  GenerateResetCodeFunction,
  ResetPasswordFunction,
  VerifyResetCodeFunction,
} from "types/functions";
import { BaseAsyncHook } from "types/hooks";

interface UseSignOut extends Omit<BaseAsyncHook, "loading" | "error"> {
  isGenerateResetCodeLoading: boolean;
  isVerifyResetCodeLoading: boolean;
  isResetPasswordLoading: boolean;
  generateResetCode: GenerateResetCodeFunction;
  verifyResetCode: VerifyResetCodeFunction;
  resetPassword: ResetPasswordFunction;
  resetPasswordError: BaseStringError;
}

/**
 * Functions and logic related to *interacting* with reseting password logic.
 *
 * Should be consumed by UI components.
 */
export const useResetPassword = (): UseSignOut => {
  const _generateResetCode = useUserStore((state) => state.generateResetCode);
  const _verifyResetCode = useUserStore((state) => state.verifyResetCode);
  const _resetPassword = useUserStore((state) => state.resetPassword);
  const [resetPasswordError, setResetPasswordError] = useState<boolean>(false);
  const [isGenerateResetCodeLoading, setIsGenerateResetCodeLoading] =
    useState<boolean>(false);
  const [isVerifyResetCodeLoading, setIsVerifyResetCodeLoadingLoading] =
    useState<boolean>(false);
  const [isResetPasswordLoading, setIsResetPasswordLoading] =
    useState<boolean>(false);

  const generateResetCode = async (
    ...args: Parameters<GenerateResetCodeFunction>
  ) => {
    try {
      setIsGenerateResetCodeLoading(true);
      await _generateResetCode(...args);
    } catch (error) {
    } finally {
      setIsGenerateResetCodeLoading(false);
    }
  };

  const resetPassword = async (...args: Parameters<ResetPasswordFunction>) => {
    try {
      setIsResetPasswordLoading(true);
      setResetPasswordError(false);
      await _resetPassword(...args);
    } catch (error) {
      setResetPasswordError(true);
    } finally {
      setIsResetPasswordLoading(false);
    }
  };

  const verifyResetCode = async (
    ...args: Parameters<VerifyResetCodeFunction>
  ) => {
    try {
      setIsVerifyResetCodeLoadingLoading(true);
      await _verifyResetCode(...args);
      return true;
    } catch (error) {
      return false;
    } finally {
      setIsVerifyResetCodeLoadingLoading(false);
    }
  };

  return {
    generateResetCode: generateResetCode,
    resetPassword,
    verifyResetCode,
    isGenerateResetCodeLoading,
    isResetPasswordLoading,
    isVerifyResetCodeLoading,
    resetPasswordError: resetPasswordError
      ? {
          didHappen: resetPasswordError,
          message: "Error occured, please try again.",
        }
      : {
          didHappen: resetPasswordError,
        },
  };
};
