import create from "zustand";

import { AppSettingsService } from "services";
import { GetAppPricesFunction, SetAppPricesFunction } from "types/functions";
import { AppPricesInStore } from "types/store";

interface AppSettingsStore {
  appPrices: AppPricesInStore;
  getAppPrices: GetAppPricesFunction;
  setAppPrices: SetAppPricesFunction;
}

export const useAppSettingsStore = create<AppSettingsStore>((set) => ({
  appPrices: undefined,
  getAppPrices: async (...args: Parameters<GetAppPricesFunction>) => {
    try {
      const prices = await AppSettingsService.getAppPrices(...args);
      // @ts-ignore
      set({ appPrices: structuredClone(prices) });
      return prices;
    } catch (error) {
      throw error;
    }
  },
  setAppPrices: async (...args: Parameters<SetAppPricesFunction>) => {
    try {
      const prices = await AppSettingsService.setAppPrices(...args);
      // @ts-ignore
      set({ appPrices: structuredClone(prices) });
      return prices;
    } catch (error) {
      throw error;
    }
  },
}));
