import { HttpService } from "services";
import { BulkUploadGroup, Listing } from "types/entities";
import {
  GetBulkGroupFunction,
  GetBulkGroupFunctionParams,
} from "types/functions";
import { AuthorizationLevel } from "types/http";

/**
 * ...
 *
 * @throws {HttpServiceException}
 */
export const getBulkGroup: GetBulkGroupFunction = async (
  params: GetBulkGroupFunctionParams
) => {
  try {
    const { groupId } = params;

    return await HttpService.fetch<Listing[]>(
      `/listing/bulk/${groupId}`,
      {
        requestConfig: {
          method: "GET",
        },
      },
      AuthorizationLevel.AUTHORIZED
    );
  } catch (error) {
    throw error;
  }
};
