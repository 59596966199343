import {
  Button,
  FormControlLabel,
  Grid,
  Link,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import { notification } from "antd";
import {
  CardModal,
  ErrorMessage,
  InputField,
  LoadingIndicator,
  Text,
} from "components/common";
import {
  useGetListing,
  useGetListings,
  useRemoveListing,
  useUpdateListing,
} from "hooks";
import React, { useEffect, useState } from "react";
import { DetailedListing } from "types/entities";
import { RemoveListingFunction } from "types/functions";
import { formatListingAddress } from "utils";

const INITIAL_FORM: DetailedListing = {
  id: "",
  address_line_1: "",
  address_line_2: "",
  approved: false,
  assigned_schools_refresh_date: "",
  availability: "",
  awaiting_approval: false,
  buy_now_price: 0,
  city: "",
  closing_date: "",
  description: "",
  has_ac: false,
  has_pool: false,
  home_type: "",
  is_pending: false,
  last_date_of_occupancy: "",
  latitude: 0,
  list_price: 0,
  listing_date: "",
  longitude: 0,
  lot_size_sqft: 0,
  notification_sent: false,
  num_baths: "",
  num_beds: 0,
  occupied: false,
  photo_urls: [],
  roof_age: "",
  roof_type: "",
  seller_id: "",
  showing_duration_minutes: 0,
  showing_step_minutes: 0,
  sqft: 0,
  state: "",
  virtual_walkthrough_url: "",
  year_built: 0,
  zip: "",
  features: {},
  conditions: {},
  defects: {},
  flood_info: {},
  more_info: {},
  exclusions: {
    id: "",
    listing_id: "",
    has_exclusions: false,
    exclusion_1: null,
    exclusion_2: null,
    exclusion_3: null,
    exclusion_4: null,
    exclusion_5: null,
  },
};

const INITIAL_MODAL_STATE = {
  formattedAddress: "",
  isOpen: false,
};

interface OwnProps {
  listing: DetailedListing;
  loadingRemoveListing: boolean;
  errorRemoveListing: boolean;
  removeListing: RemoveListingFunction;
}

enum ListingStatus {
  AWAITING_APPROVAL = "awaiting_approval",
  APPROVED = "approved",
  DENIED = "denied",
}

export const ManageListingsUpdateForm: React.FC<OwnProps> = ({
  listing,
  loadingRemoveListing,
  errorRemoveListing,
  removeListing,
}) => {
  const { updateListing } = useUpdateListing();
  const { getListing } = useGetListing();
  const { getListings } = useGetListings();
  const [form, setForm] = useState({ ...INITIAL_FORM });
  const [modalState, setModalState] = useState({ ...INITIAL_MODAL_STATE });
  const gridItemStyle: React.CSSProperties = { width: "100%", padding: "5px" };
  const [api, contextHolder] = notification.useNotification();

  const handleUpdateForm = (
    key: keyof DetailedListing,
    val: string,
    type: "string" | "number" = "string"
  ) => {
    let newVal;
    if (type === "number") newVal = parseFloat(val);
    else newVal = val;
    setForm({ ...form, [key]: newVal });
  };

  const handleRemoveListing = (listing: DetailedListing) => {
    removeListing({ listingId: listing.id }).then(() => getListings());
  };

  const handleChangeListingStatus = (
    option: ListingStatus,
    checked: boolean
  ): void => {
    const newStatus = {
      awaiting_approval: false,
      approved: false,
    };
    switch (option) {
      case ListingStatus.AWAITING_APPROVAL:
        newStatus.awaiting_approval = true;
        newStatus.approved = false;
        break;
      case ListingStatus.APPROVED:
        newStatus.awaiting_approval = false;
        newStatus.approved = true;
        break;
      case ListingStatus.DENIED:
        newStatus.awaiting_approval = false;
        newStatus.approved = false;
        break;
      default:
        alert("handleChangeListingStatus: invalid option has been selected");
        break;
    }
    setForm({ ...form, ...newStatus });
  };

  const determineListingStatus = (
    listing: DetailedListing,
    status: ListingStatus
  ): boolean => {
    let isGivenStatus = false;
    switch (status) {
      case ListingStatus.AWAITING_APPROVAL:
        if (listing.awaiting_approval && !listing.approved)
          isGivenStatus = true;
        break;
      case ListingStatus.APPROVED:
        if (!listing.awaiting_approval && listing.approved)
          isGivenStatus = true;
        break;
      case ListingStatus.DENIED:
        if (!listing.awaiting_approval && !listing.approved)
          isGivenStatus = true;
        break;
      default:
        break;
    }
    return isGivenStatus;
  };

  const handleUpdateListing = () => {
    updateListing({ listing: form }).then(() => {
      api.success({
        message: `Successfully updated listing`,
        placement: "bottomRight",
      });
      return getListing({ listingId: listing.id });
    });
  };

  const generateLabel = (text: string) => (
    <Text variant="h5" fontWeight={600} marginTop="20px">
      {text}
    </Text>
  );

  useEffect(() => setForm({ ...INITIAL_FORM, ...listing }), [listing]);

  useEffect(() => {
    if (!loadingRemoveListing && !errorRemoveListing && modalState.isOpen) {
      setModalState({ ...INITIAL_MODAL_STATE });
    }
  }, [loadingRemoveListing, errorRemoveListing]);

  return (
    <>
      <Grid container spacing={2} alignItems="flex-start">
        {contextHolder}
        <Grid xs={4} item>
          <div style={gridItemStyle}>{generateLabel("Description")}</div>
          <div style={gridItemStyle}>
            <InputField
              fullWidth
              multiline
              maxRows={5}
              label="Description"
              value={form.description || ""}
              onChange={(val) => handleUpdateForm("description", val)}
            />
          </div>

          <div style={gridItemStyle}>{generateLabel("Approval Status")}</div>
          <RadioGroup>
            <div style={gridItemStyle}>
              <FormControlLabel
                control={
                  <Radio
                    onChange={(e) =>
                      handleChangeListingStatus(
                        ListingStatus.APPROVED,
                        e.target.checked
                      )
                    }
                    checked={determineListingStatus(
                      form,
                      ListingStatus.APPROVED
                    )}
                  />
                }
                label="Approved"
              />
            </div>
            <div style={gridItemStyle}>
              <FormControlLabel
                control={
                  <Radio
                    onChange={(e) =>
                      handleChangeListingStatus(
                        ListingStatus.DENIED,
                        e.target.checked
                      )
                    }
                    checked={determineListingStatus(form, ListingStatus.DENIED)}
                  />
                }
                label="Denied"
              />
            </div>
            <div style={gridItemStyle}>
              <FormControlLabel
                control={
                  <Radio
                    onChange={(e) =>
                      handleChangeListingStatus(
                        ListingStatus.AWAITING_APPROVAL,
                        e.target.checked
                      )
                    }
                    checked={determineListingStatus(
                      form,
                      ListingStatus.AWAITING_APPROVAL
                    )}
                  />
                }
                label="Awaiting Approval / Pending"
              />
            </div>
          </RadioGroup>
        </Grid>

        <Grid xs={4} item>
          <div style={gridItemStyle}>{generateLabel("Address")}</div>
          <div style={gridItemStyle}>
            <InputField
              fullWidth
              label="Address Line 1"
              value={form.address_line_1 || ""}
              onChange={(val) => handleUpdateForm("address_line_1", val)}
            />
          </div>
          <div style={gridItemStyle}>
            <InputField
              fullWidth
              label="Address Line 1"
              value={form.address_line_2 || ""}
              onChange={(val) => handleUpdateForm("address_line_2", val)}
            />
          </div>
          <div style={gridItemStyle}>
            <InputField
              fullWidth
              label="City"
              value={form.city || ""}
              onChange={(val) => handleUpdateForm("city", val)}
            />
          </div>
          <div style={gridItemStyle}>
            <InputField
              fullWidth
              label="State"
              value={form.state || ""}
              onChange={(val) => handleUpdateForm("state", val)}
            />
          </div>
          <div style={gridItemStyle}>
            <InputField
              fullWidth
              label="Zip"
              value={form.zip || ""}
              onChange={(val) => handleUpdateForm("zip", val)}
            />
          </div>

          <div style={gridItemStyle}>{generateLabel("Other Data")}</div>
          <div style={gridItemStyle}>
            <InputField
              fullWidth
              label="Beds"
              type="number"
              value={form.num_beds || ""}
              onChange={(val) => handleUpdateForm("num_beds", val, "number")}
            />
          </div>
          <div style={gridItemStyle}>
            <InputField
              fullWidth
              label="Baths"
              value={form.num_baths || ""}
              type="number"
              onChange={(val) => handleUpdateForm("num_baths", val, "number")}
            />
          </div>
          <div style={gridItemStyle}>
            <InputField
              fullWidth
              label="Square Ft"
              value={form.sqft || ""}
              onChange={(val) => handleUpdateForm("sqft", val, "number")}
            />
          </div>
          <div style={gridItemStyle}>
            <InputField
              fullWidth
              label="Lot Size"
              value={form.lot_size_sqft || ""}
              onChange={(val) =>
                handleUpdateForm("lot_size_sqft", val, "number")
              }
            />
          </div>
        </Grid>

        <Grid xs={4} item>
          <div style={gridItemStyle}>{generateLabel("List Price")}</div>
          <div style={gridItemStyle}>
            <InputField
              fullWidth
              label="List Price"
              value={form.list_price || ""}
              onChange={(val) => handleUpdateForm("list_price", val, "number")}
            />
          </div>

          <div style={gridItemStyle}>{generateLabel("Coordinates")}</div>
          <div style={gridItemStyle}>
            <InputField
              fullWidth
              label="Latitude"
              value={form.latitude || ""}
              onChange={(val) => handleUpdateForm("latitude", val, "number")}
            />
          </div>
          <div style={gridItemStyle}>
            <InputField
              fullWidth
              label="Longitude"
              value={form.longitude || ""}
              onChange={(val) => handleUpdateForm("longitude", val, "number")}
            />
          </div>
          <Text>
            These values should be handled by google autocomplete. If they are
            missing you should add them manually.
          </Text>
          <Text className="margin-top-10">
            You can use the following link to approximate the coordinates(right
            click a spot on the map, note (latitude, longitude) and add them
            here)
          </Text>
          <div className="margin-top-10">
            <Link
              href="https://www.google.com/maps"
              underline="hover"
              target="_blank"
            >
              https://www.google.com/maps
            </Link>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="flex-end"
        className="margin-top-10"
        spacing={2}
      >
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="error"
            fullWidth
            onClick={() =>
              setModalState({
                isOpen: true,
                formattedAddress: formatListingAddress(listing),
              })
            }
          >
            Remove Listing
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button variant="contained" fullWidth onClick={handleUpdateListing}>
            Update
          </Button>
        </Grid>
      </Grid>

      <CardModal
        isOpen={modalState.isOpen}
        onClose={() => setModalState({ ...INITIAL_MODAL_STATE })}
      >
        <Stack spacing={2} direction="column">
          <Text variant="h6">
            Are you sure you want to remove this listing from the database?
          </Text>
          <Text variant="subtitle1">{modalState.formattedAddress}</Text>
          {errorRemoveListing && <ErrorMessage />}
          <Button
            variant="contained"
            type="submit"
            disabled={loadingRemoveListing}
            onClick={() => handleRemoveListing(listing)}
          >
            {loadingRemoveListing ? (
              <LoadingIndicator size={24} indicatorColor="white" />
            ) : (
              "Confirm"
            )}
          </Button>
        </Stack>
      </CardModal>
    </>
  );
};
