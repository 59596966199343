import { useState } from "react";
import { useUserStore } from "store";

import { SignOutFunction } from "types/functions";
import { BaseAsyncHook } from "types/hooks";

interface UseSignOut extends BaseAsyncHook {
  loading: boolean;
  error: boolean;
  signOut: SignOutFunction;
}

export const useSignOut = (): UseSignOut => {
  const _signOut = useUserStore((state) => state.signOut);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const signOut = async () => {
    try {
      setIsLoading(true);
      await _signOut();
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error: isError,
    loading: isLoading,
    signOut,
  };
};
