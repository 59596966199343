import { useState } from "react";

import { useUserStore } from "store";
import { UserRole } from "types/entities";
import { CreateUserFunction } from "types/functions";
import { BaseAsyncHook } from "types/hooks";

interface UseCreateUser extends BaseAsyncHook {
  loading: boolean;
  error: boolean;
  createUser: CreateUserFunction;
}

export const useCreateUser = (): UseCreateUser => {
  const _createUser = useUserStore((state) => state.createUser);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const createUser = async (...args: Parameters<CreateUserFunction>) => {
    try {
      setIsLoading(true);
      setIsError(false);
      const parameters = args[0];
      if (!parameters.roles.has(UserRole.VENDOR)) {
        parameters.vendorRole = undefined;
      }
      await _createUser(parameters);
      return true;
    } catch (error) {
      setIsError(true);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error: isError,
    loading: isLoading,
    createUser,
  };
};
