import {
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from "@mui/material";
import React from "react";

interface OwnProps<T> {
  options: {
    label: string;
    value: T;
  }[];
  value: Set<T>;
  label: string;
  onChange: (value: Set<T>) => void;
}

type TCheckboxGroup<T> = OwnProps<T>;

export const CheckboxGroup = <T,>({
  onChange,
  options,
  value: currentSet,
  label,
}: TCheckboxGroup<T>) => {
  const updateSet = (setValue: T) => {
    const updatedSet = currentSet;
    if (currentSet.has(setValue)) {
      updatedSet.delete(setValue);
    } else {
      updatedSet.add(setValue);
    }
    onChange(new Set(updatedSet));
  };

  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <FormGroup row>
        {options.map((option, i) => (
          <FormControlLabel
            key={i}
            control={
              <Checkbox
                checked={currentSet.has(option.value)}
                onChange={() => {
                  updateSet(option.value);
                }}
              />
            }
            label={option.label}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};
