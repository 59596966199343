import {
  GetChannelsFunction,
  GetChannelsFunctionParams,
} from "types/functions";

/**
 * Retrieve sendbird channels
 *
 * @throws {HttpServiceException}
 */
export const getChannels: GetChannelsFunction = async (
  params: GetChannelsFunctionParams
) => {
  try {
    const { sb } = params;

    const listQuery = sb.GroupChannel.createMyGroupChannelListQuery();
    listQuery.includeEmpty = true;
    listQuery.memberStateFilter = "joined_only";
    listQuery.order = "latest_last_message";
    listQuery.limit = 100;

    if (listQuery.hasNext) {
      const groupChannels = await listQuery.next((groupChannels, error) => {
        if (error) {
          throw error;
        }
        if (!groupChannels) return [];
        return groupChannels;
      });

      return groupChannels;
    }
    return [];
  } catch (error) {
    throw error;
  }
};
