import { useState } from "react";

import { useUserStore } from "store";
import { AsyncFunction, EntityId } from "types/common";
import { BaseAsyncHook } from "types/hooks";

type RelatedEntityId = EntityId | undefined;

type SelectFunction = (id: RelatedEntityId) => void;

interface UseAssignVendorToEvent extends BaseAsyncHook {
  assignVendorToEvent: AsyncFunction;
  selectVendor: SelectFunction;
  selectEvent: SelectFunction;
  canPerformAssignment: boolean;
  selectedVendorId: RelatedEntityId;
}

export const useAssignVendorToEvent = (): UseAssignVendorToEvent => {
  const _assignVendorToEventStore = useUserStore(
    (state) => state.assignVendorToEvent
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [selectedEventId, setSelectedEventId] = useState<RelatedEntityId>();
  const [selectedVendorId, setSelectedVendorId] = useState<RelatedEntityId>();

  const canPerformAssignment =
    selectedEventId !== undefined && selectedVendorId !== undefined;

  const _assignVendorToEvent = async () => {
    if (!canPerformAssignment) return;
    try {
      setIsLoading(true);
      setIsError(false);
      await _assignVendorToEventStore({
        eventId: selectedEventId,
        vendorId: selectedVendorId,
      });
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error: isError,
    loading: isLoading,
    assignVendorToEvent: _assignVendorToEvent,
    selectVendor: setSelectedVendorId,
    selectEvent: setSelectedEventId,
    canPerformAssignment,
    selectedVendorId,
  };
};
