import { HttpService } from "services";
import { ApiAppPrice } from "types/api";
import { SetAppPricesFunction } from "types/functions";
import { AuthorizationLevel } from "types/http";

/**
 *
 *
 * @throws {AppSettingsServiceException}
 */
export const setAppPrices: SetAppPricesFunction = async (
  ...args: Parameters<SetAppPricesFunction>
) => {
  try {
    const [prices] = args;
    const response = await HttpService.fetch<ApiAppPrice[]>(
      "/price",
      {
        requestConfig: {
          method: "PATCH",
          data: {
            prices,
          },
        },
      },
      AuthorizationLevel.AUTHORIZED
    );

    return HttpService.Serializer.serializeAppPrices(response);
  } catch (error) {
    throw error;
  }
};
