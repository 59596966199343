import { useState } from "react";

import { useTitleStore } from "store";
import { GetTitlesFunction } from "types/functions";
import { BaseAsyncHook } from "types/hooks";

interface UseGetTitles extends BaseAsyncHook {
  getTitles: GetTitlesFunction;
}

export const useGetTitles = (): UseGetTitles => {
  const _stateFunc = useTitleStore((state) => state.getTitles);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const getTitles = async (...args: Parameters<GetTitlesFunction>) => {
    try {
      setIsLoading(true);
      setIsError(false);
      const titles = await _stateFunc(args[0]);
      return titles;
    } catch (error) {
      setIsError(true);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error: isError,
    loading: isLoading,
    getTitles,
  };
};
