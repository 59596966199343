import { MenuItem, TextField } from "@mui/material";
import React from "react";

interface OwnProps<T> {
  label?: string;
  value: T;
  options: {
    value: T;
    label: string;
  }[];
  onChange: (value: T) => void;
}

type TSelect<T extends string | number> = OwnProps<T>;

export const Select = <T extends string | number>({
  onChange,
  options,
  value,
  label,
}: TSelect<T>) => {
  return (
    <TextField
      value={value}
      onChange={(e) => onChange(e.target.value as T)}
      label={label}
      select
    >
      {options.map((option, i) => (
        <MenuItem key={i} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};
