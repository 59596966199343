import { SendbirdChat } from "components/messages";
import { ViewWrapper } from "components/wrappers";
import { useGetListings, useSendBird } from "hooks";
import { useGetChannels } from "hooks/useGetChannels";
import { useEffect, useState } from "react";
import { GroupChannel } from "sendbird";
import { useMessagesStore } from "store";
import "../../assets/styles/components/messages/styles.css";

export const Messages = () => {
  const [selectedChannel, setSelectedChannel] = useState<GroupChannel | null>(null);
  const {
    getChannels,
    loading: loadingChannels,
    error: errorChannels,
  } = useGetChannels();
  const {
    getListings,
  } = useGetListings();
  const { channels } = useMessagesStore();
  const { sb } = useSendBird();

  useEffect(() => {
    getChannels({ sb });
    getListings();
  }, []);

  return (
    <ViewWrapper title="Messages">
      <SendbirdChat />
    </ViewWrapper>
  );
};
